import log from "cslog";
import { startCase } from "lodash";

export function isDBValid(block) {
	const data = block.data || {};
	let isValid = false;
	switch (block.block_type) {
		case "qureal_database":
			isValid = data.name && data.table && data.type;
			if (isValid) {
				if (data.filter?.type === "fixed") {
					isValid = data.filter?.value;
				} else if (data.filter?.type === "url_path") {
					isValid = data.filter?.path && data.filter?.field;
				} else if (data.filter.type === "url_param") {
					isValid = data.filter?.param && data.filter?.param;
				} else if (data.filter.type === "input") {
					isValid = data.filter?.param && data.display;
				}
			}
			if (isValid) return true;
			return false;
		case "fillable":
			log.d(data, "checking data in fillable");
			isValid = block.name && data.fields?.length > 0;
			if (isValid) {
				data.fields.forEach((item) => {
					if (!(item.name && item.type)) {
						return false;
					}
				});
				return true;
			}
			return false;
		case "CSV":
			isValid = block.name && data.data_file && data?.fields?.length > 0;
			return isValid;
		default:
			return false;
	}
}

const OP_READABLE = {
	_eq: "equals to",
	_neq: "not equal to",
	_null: "is null",
	_nnull: "isn't null",
};

export const checkSingleCondition = (data, cond) => {
	log.d([data, cond], "CheckingSingleCond");
	const v1 = data[cond.field];
	const v2 = cond.value?.value;

	switch (cond.op) {
		case "_eq":
			return v1 === v2;
		case "_neq":
			return v1 !== v2;
		case "_null":
			return !v1;
		case "_nnull":
			return !!v1;
		default:
			return false;
	}
};

export const getReadableCond = (cond) => {
	return `${startCase(cond.field)} ${OP_READABLE[cond.op]} ${typeof cond.value?.value !== "boolean" ? cond.value?.value : ""
		}`;
};

// true = show if conditions match
export const checkConditions = (db, conds) => {
	if (conds && conds?.conditions?.length > 0) {
		log.d(db, "DB");
		log.d(conds?.conditions, "Conditions");
		let out = true;
		conds?.conditions?.every((cond) => {
			const value = db[cond.id];
			if (value.isSuccess) {
				if (!checkSingleCondition(value.data, cond)) {
					out = false;
					return false;
				}
				return true;
			} else {
				out = false;
				return false;
			}
		});
		log.d(out, "OUT");

		return out;
	}
	return true;
};
