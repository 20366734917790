import log from "cslog";
import { nanoid } from "nanoid";
import { useEffect, useMemo } from "react";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AutoFit from "../../commons/AutoFit";
import { RenderSinglePage } from "../../renderer/absolute/Renderer";
import { useAtom } from "jotai";
import { fillableAllState } from "../../junctionAbs";

const SinglePageView = ({ data, isPreview, ctx }) => {
	const info = data.info;
	const layout = data.layout["default"];

	const [fdb, setFdb] = useAtom(fillableAllState);

	log.d(data, "Fresh DATA");

	const page = data.data.pages[0];
	log.d(page, "Fresh Data page");

	const protected_access = data.permissions?.protected_access || {};

	const creation_id = data.creation; //only availabe in rendering (show)

	const vi = data.viewers.input;

	const refreshKey = useMemo(() => nanoid(5), [data]);

	let visibleOne = true;

	const query_params = ctx?.query_params || {};
	const is_preview = Object.keys(query_params).includes("preview");
	// const is_preview = false;

	// fill fillable with query params
	const fillWithQueryParams = () => {
		const params = ctx?.query_params || {};
		const param_keys = Object.keys(params);
		const content = page.content;
		if (
			content &&
			Object.keys(content).length > 0 &&
			Object.keys(params).length > 0
		) {
			// fillable block exists & params exists
			Object.keys(content).forEach((block_key) => {
				const params_with_this_block_key = param_keys.filter((item) =>
					item.startsWith(block_key),
				);
				if (params_with_this_block_key.length > 0) {
					const ready_to_fill = {};
					params_with_this_block_key.forEach((param_key) => {
						if (param_key.includes(".")) {
							const inner_key = param_key.split(".")[1];
							const inner_value = params[param_key];
							ready_to_fill[inner_key] = inner_value;
						}
					});
					if (Object.keys(ready_to_fill).length > 0) {
						// now update fillable block
						setFdb((old_fbs) => ({
							...old_fbs,
							[block_key]: ready_to_fill,
						}));
					}
				}
			});
		}
	};

	useEffect(() => {
		fillWithQueryParams();
	}, []);

	return (
		<AutoFit
			layout={layout}
			page_content={page.content}
			is_preview={is_preview}
			gap={is_preview ? 0 : 2}
			protected_access={protected_access}
			creation_id={creation_id}
		>
			<RenderSinglePage
				page={page}
				data={data}
				fontSet={null}
				ctx={ctx}
			/>
		</AutoFit>
	);
};

export default SinglePageView;

// Best
// https://swiperjs.com/demos

// Good
// http://react-responsive-carousel.js.org/storybook/?path=/story/02-advanced--presentation-mode

// list
// https://www.google.com/search?q=react+carousel&oq=react+cara&aqs=chrome.1.69i57j0i10l9.4827j0j9&sourceid=chrome&ie=UTF-8
