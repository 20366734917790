import React from "react";

function FullScreenCenter({
    children,
    gap = 10,
    height = "100vh",
    logo = true,
}) {
    return (
        <div
            style={{
                width: "100%",
                height: height,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: gap,
            }}
        >
            {logo && (
                <img
                    // src={process.env.PUBLIC_URL + "/logo.svg"}
                    src="https://api.qureal.com/assets/cb72d577-42ce-4715-8ea6-576df2c808c7"
                    alt="Qureal Logo"
                    style={{
                        width: "70px",
                        // opacity: 0.2,
                    }}
                />
            )}
            {children}
        </div>
    );
}

export default FullScreenCenter;
