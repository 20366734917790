const base = {
	background: "yellow",
};

export const VIEWERS = {
	default: { input: {}, itype: {} },
	cube: {
		input: {
			zoom: 90,
			showArrow: true,
			showDots: true,
			paginationStyle: "bullets",
			swipeDirection: "horizontal",
			infiniteLoop: true,
			cubeShadow: true,
		},
		itype: {
			zoom: "standardRange",
			showArrow: "boolean",
			showDots: "boolean",
			paginationStyle: "selectOption",
			swipeDirection: "selectOption",
			infiniteLoop: "boolean",
			cubeShadow: "boolean",
		},
	},
	stories: {
		input: {
			showArrow: true,
			showScrollbar: false,
			showDots: true,
			paginationStyle: "bullets",
			swipeDirection: "horizontal",
			infiniteLoop: false,
		},
		itype: {
			showArrow: "boolean",
			showScrollbar: "boolean",
			showDots: "boolean",
			paginationStyle: "selectOption",
			swipeDirection: "selectOption",
			infiniteLoop: "boolean",
		},
	},
	slider: {
		input: {
			zoom: 90,
			sliderEffect: "slide",
			containedView: false,
			gapBetweenSlides: 10,
			showArrow: true,
			showScrollbar: false,
			showDots: true,
			paginationStyle: "bullets",
			swipeDirection: "horizontal",
			infiniteLoop: false,
		},
		itype: {
			containedView: "boolean",
			zoom: "standardRange",
			sliderEffect: "selectOption",
			gapBetweenSlides: "standardRange",
			showArrow: "boolean",
			showScrollbar: "boolean",
			showDots: "boolean",
			paginationStyle: "selectOption",
			swipeDirection: "selectOption",
			infiniteLoop: "boolean",
		},
	},
	qdf: {
		input: {
			width: 90,
			gapBetweenPages: 10,
			gapOnTop: 35,
			showScrollbar: false,
		},
		itype: {
			width: "standardRange",
			gapBetweenPages: "standardRange",
			gapOnTop: "standardRange",
			showScrollbar: "boolean",
		},
	},
	magazine: {
		input: {
			zoom: 100,
			gapOnTop: 50,
		},
		itype: {
			zoom: "standardRange",
			gapOnTop: "standardRange",
		},
	},
	flipbook: {
		input: {
			zoom: 100,
			gapOnTop: 50,
		},
		itype: {
			zoom: "standardRange",
			gapOnTop: "standardRange",
		},
	},
	card: {
		input: {
			zoom: 90,
			showArrow: false,
			showDots: false,
			paginationStyle: "bullets",
			swipeDirection: "horizontal",
			infiniteLoop: true,
		},
		itype: {
			zoom: "standardRange",
			showArrow: "boolean",
			showDots: "boolean",
			paginationStyle: "selectOption",
			swipeDirection: "selectOption",
			infiniteLoop: "boolean",
		},
	},
	cards: {
		input: {
			zoom: 90,
			containedView: false,
			gapBetweenSlides: 10,
			showArrow: true,
			showScrollbar: false,
			showDots: true,
			paginationStyle: "bullets",
			swipeDirection: "horizontal",
			infiniteLoop: false,
		},
		itype: {
			containedView: "boolean",
			zoom: "standardRange",
			gapBetweenSlides: "standardRange",
			showArrow: "boolean",
			showScrollbar: "boolean",
			showDots: "boolean",
			paginationStyle: "selectOption",
			swipeDirection: "selectOption",
			infiniteLoop: "boolean",
		},
	},
};
