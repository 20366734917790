import { useEffect } from "react";
import BookPage from "./elements_freeview_static/book/BookPagePreview";
import { QueryClientProvider } from "react-query";
// import { queryClient } from "../../../_helpers/query";
import { queryClient } from "../../query";
// import WebFont from "webfontloader";

export const getRenderedPages = (data, ctx) => {
    const pages = data.data.pages;
    console.log("Pages", pages);

    const globalPage = pages.filter((page) => page.pid === "globalPage")[0];

    const gs = {
        config: {
            ...data.gconfig.gconfig.colors,
            ...data.gconfig.gconfig.fontSets,
            // ...pageSettings,
        },
        gstyles: data.gconfig.gstyles.gstyles,
    };

    return pages
        .filter((page) => page.pid !== "globalPage")
        .map((page, index) => {
            return (
                <div key={index} style={{ overflow: "hidden" }}>
                    <QueryClientProvider client={queryClient}>
                        <BookPage
                            id={page.pid}
                            db={page.html}
                            globalDb={globalPage.html}
                            gs={gs}
                            dydb={page.content}
                            globalConfig={data.gconfig.globalConfig}
                            bookLayout={data.layout[page.size]}
                            applyGlobal={page.size === "default"}
                            ctx={ctx}
                        />
                    </QueryClientProvider>
                </div>
            );
        });
};

const Renderer = ({ data }) => {
    const pages = data.data.pages;
    // console.log("Pages", pages);

    const globalPage = pages.filter((page) => page.pid === "globalPage")[0];
    // console.log("GP", globalPage);

    const firstPage = pages.filter((page) => page.pid === "firstPage")[0];
    // console.log("FP", firstPage);

    return (
        <div style={{ overflow: "hidden" }}>
            <BookPage
                id={firstPage.pid}
                db={firstPage.html}
                globalDb={globalPage.html}
                gs={data.gconfig.gstyle}
                globalConfig={data.gconfig.globalConfig}
                bookLayout={data.layout}
            />
        </div>
    );
};

export const RenderPage = ({ page, globalPage, data }) => {
    return (
        <div style={{ overflow: "hidden" }}>
            <QueryClientProvider client={queryClient}>
                <BookPage
                    id={page.pid}
                    db={page.html}
                    globalDb={globalPage.html}
                    gs={data.gconfig.gstyle}
                    globalConfig={data.gconfig.globalConfig}
                    bookLayout={data.layout[page.size]}
                    applyGlobal={page.size !== "background"}
                    // bookLayout={data.layout["default"]}
                />
            </QueryClientProvider>
        </div>
    );
};

export const RenderSinglePage = ({ page, data, fontSet, ctx }) => {
    const gs = {
        config: {
            ...data.gconfig.gconfig.colors,
            ...data.gconfig.gconfig.fontSets,
            // ...pageSettings,
        },
        gstyles: data.gconfig.gstyles.gstyles,
    };

    // useEffect(() => {
    //     if (fontSet) {
    //         const fonts = Object.values(fontSet);
    //         WebFont.load({
    //             google: {
    //                 families: fonts,
    //             },
    //         });
    //     }
    // }, [fontSet]);

    return (
        <div style={{ overflow: "hidden" }}>
            <QueryClientProvider client={queryClient}>
                <BookPage
                    id={page.pid}
                    db={page.html}
                    gs={gs}
                    globalDb={{ globalPage: {} }}
                    dydb={page.content}
                    globalConfig={data.gconfig.globalConfig}
                    bookLayout={data.layout[page.size]}
                    applyGlobal={false}
                    ctx={ctx}
                />
            </QueryClientProvider>
        </div>
    );
};

export default Renderer;

// id={page.pid}
// db={page.html}
// globalDb={globalPage.html}
// gs={gs}
// dydb={page.content}
// globalConfig={data.gconfig.globalConfig}
// bookLayout={data.layout[page.size]}
// applyGlobal={page.size === "default"}
// ctx={ctx}
