/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import isHotkey from "is-hotkey";
import React, { useCallback, useMemo } from "react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";
import BlockButton from "./inputs/BlockButton";
import InsertLinkBtn from "./inputs/InsertLinkBtn";
import InsertImageBtn from "./inputs/InsetImageBtn";
import MarkButton from "./inputs/MarkButton";
import RemoveStyleButton from "./inputs/RemoveStyleButton";
import StyleButton from "./inputs/StyleButton";
import Leaf from "./Leaf";
import withImages from "./plugins/withImages";
import withLinks from "./plugins/withLinks";
import SlateElement from "./SlateElement";
import styles from "./styles/SlateEditor.module.css";
import "./styles/slate_design.css";
import { toggleMark } from "./utils/mark";
import { removeStyle } from "./utils/style";
import { SlateToolbarAttached } from "./SlateToolbar";

const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code",
    "mod+s": "bold",
};

const SlateEditor = ({ data, setData }) => {
    // const [value, setValue] = useState(initialValue);
    const editor = useMemo(
        () => withLinks(withImages(withHistory(withReact(createEditor())))),
        []
    );
    const renderElement = useCallback(
        (props) => <SlateElement {...props} />,
        []
    );
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

    return (
        <div
            className={styles.wrapper}
            css={css`
                .qslate p,
                .qslate li,
                .qslate blockquote {
                    font-size: ${16}px;
                }
            `}
        >
            <Slate
                editor={editor}
                // value={value}
                // onChange={(value) => setValue(value)}
                value={data}
                onChange={(value) => setData(value)}
            >
                <SlateToolbarAttached />
                <Editable
                    // renderElement={(props) => <Element {...props} />}
                    // renderLeaf={(props) => <Leaf {...props} />}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder="Enter some rich text…"
                    spellCheck
                    autoFocus
                    onKeyDown={(event) => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault();
                                const mark = HOTKEYS[hotkey];
                                toggleMark(editor, mark);
                            }
                        }
                        if (isHotkey("mod+enter", event)) {
                            // insert line break here without removing styles
                        } else if (isHotkey("enter", event)) {
                            removeStyle(editor);
                        }
                    }}
                    style={{
                        marginTop: "50px",
                        paddingBottom: "100px",
                    }}
                    className="qslate"
                />
            </Slate>
        </div>
    );
};

const initialValue = [
    {
        type: "heading-two",
        children: [
            {
                text: "Hello Slate Demo",
            },
        ],
    },
    {
        type: "paragraph",
        children: [
            {
                text: "In addition to nodes that contain editable text, you can also create other types of nodes, like images or videos.",
            },
        ],
    },
    {
        type: "image",
        url: "https://source.unsplash.com/kFrdX5IeQzI",
        children: [{ text: "" }],
    },
    {
        type: "paragraph",
        children: [
            {
                text: "This example shows images in action. It features two ways to add images. You can either add an image via the toolbar icon above, or if you want in on a little secret, copy an image URL to your keyboard and paste it anywhere in the editor!",
            },
        ],
    },
];

export default SlateEditor;
