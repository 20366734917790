import React from "react";
import FullScreenCenter from "../editor/components/FullScreenCenter";

function PleaseSelectCreation() {
    return (
        <FullScreenCenter>
            <h3>Please select creation to edit or create new creation</h3>
        </FullScreenCenter>
    );
}

export default PleaseSelectCreation;
