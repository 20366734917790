const ABS = [
    {
        id: "1x1.66",
        name: "Mobile",
        value: { width: 360, height: 600 },
    },
    {
        id: "1x1.45",
        name: "Tab",
        value: { width: 360, height: 525 },
    },
    {
        id: "1x1.25",
        name: "Paper",
        value: { width: 360, height: 450 },
    },
    {
        id: "1x1",
        name: "Square",
        value: { width: 360, height: 360 },
    },
    {
        id: "1.45x1",
        name: "Tab Horizontal",
        value: { width: 758, height: 520 },
    },
    // {
    //     id: "1.77x1",
    //     name: "Computer",
    //     value: { width: 832, height: 468 },
    // },
];

export const CONVERSION_RATE = {
    mm: 3.7795,
    cm: 37.795,
    in: 3,
};
export const pageSizes = {
    print: [
        // in mm
        // {
        //     id: "fit",
        //     name: "Fit",
        //     value: { width: 10, height: 10, unit: "mm" },
        // },
        {
            id: "A4",
            name: "A4",
            value: { width: 210, height: 297, unit: "mm" },
        },
        {
            id: "A3",
            name: "A3",
            value: { width: 297, height: 420, unit: "mm" },
        },
        {
            id: "A5",
            name: "A5",
            value: { width: 148, height: 210, unit: "mm" },
        },
        {
            id: "Check",
            name: "Check",
            value: { width: 2100, height: 2970, unit: "mm" },
        },
    ],
    magazine_old: [
        {
            id: "magazine",
            name: "Magazine (11x14)",
            value: { width: 440, height: 540 },
        },
        {
            id: "a4",
            name: "A4 (70x99)",
            value: { width: 428, height: 594 },
        },
        {
            id: "square",
            name: "Square (1x1)",
            value: { width: 480, height: 480 },
        },
        {
            id: "landscape",
            name: "Landscape (5x4)",
            value: { width: 500, height: 400 },
        },
        {
            id: "trade",
            name: "Trade (13x20)",
            value: { width: 390, height: 600 },
        },
        {
            id: "custom",
            name: "Custom",
            value: null,
        },
    ],
    ad: [
        {
            id: "banner",
            name: "Banner",
            value: { width: 600, height: 200 },
        },
        {
            id: "fullpage",
            name: "Full Page",
            value: { width: 440, height: 540 },
        },
    ],
    card: [...ABS],
    cardstack: [...ABS],
    stories: [...ABS],
    slider: [
        ...ABS,
        {
            id: "custom",
            name: "Custom",
            value: { width: 360, height: 600 },
        },
    ],
    cube: [...ABS],
    cards: [...ABS],
    qdf: [...ABS],
    magazine: [...ABS],
    singlepage: [
        ...ABS,
        {
            id: "custom",
            name: "Custom",
            value: { width: 360, height: 600 },
        },
    ],
    packaging_box: [
        {
            id: "custom",
            name: "Custom",
            value: { width: 100, height: 100 },
        },
    ],
};

// max= 2.16
// idle= 1.66
// min= 1.57
export const backdropSizes = {
    mobile: {
        id: "mobile",
        type: "mobile",
        name: "Mobile",
        value: { width: 380, height: 820 },
        inner: { width: 360, height: 600 },
    },
    tab: {
        id: "tab",
        type: "tab",
        name: "Tab",
        // value: { width: 785, height: 1256 },
        value: { width: 460, height: 736 },
        // inner: { width: 768, height: 1024 },
        inner: { width: 450, height: 600 },
    },
    desktop: {
        id: "desktop",
        type: "desktop",
        name: "Desktop",
        value: { width: 1019, height: 475 },
        inner: { width: 832, height: 468 },
    },
};

const generalPageHelp = {
    globalPage: "This page will appear as all page's background",
    firstPage: "First Page",
    backdropMobile:
        "Backdrop for mobile devices. Dashed frame represents safe working zone which will be visible in all screen sizes mobile devies.",
    backdropDesktop: "Backdrop for desktop devices",
};
export const PAGE_HELPS = {
    slider: {
        ...generalPageHelp,
    },
    card: {
        ...generalPageHelp,
        frontSide: "Front of the card",
        backSide: "Back of the card",
    },
    cube: {
        ...generalPageHelp,
    },
    cards: {
        ...generalPageHelp,
    },
    qdf: {
        ...generalPageHelp,
    },
};

// Tab Screen Sizes
// 1024 x 1366  = 1.33
// 768   x 1024  = 1.33
// 600   x  960   = 1.60
// 800   x  1280 = 1.60
// 900   x  1280 = 1.42
