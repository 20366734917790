/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import { useAtom } from "jotai";
import React from "react";
import { baseURLAtom } from "../../../db";
import { decodeStyle, parseImgURL, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";
import SVG from "react-inlinesvg";

function Graphic({ id, db, gs, dydb }) {
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    let input = ps.input;

    const svgSource = input.svgSource;
    const imgSrc = parseImgURL(input.svgSource);

    const replaceColors = (code, og_colors, colors) => {
        if (colors?.length > 0) {
            for (let i = 0; i < colors.length; i++) {
                if (svgSource.colors?.[i] !== og_colors?.[i]) {
                    code = code.replaceAll(og_colors[i], colors[i]);
                }
            }
        }
        return code;
    };

    return (
        <ElementWrapper id={id}>
            <SVG
                key={svgSource.colors?.join("-")}
                src={imgSrc}
                width={"100%"}
                height={"100%"}
                // width={200}
                title="qureal svg"
                preProcessor={(code) => {
                    code = replaceColors(
                        code,
                        svgSource.og_colors,
                        svgSource.colors
                    );
                    // code.replace(/fill=".*?"/g, 'fill="red"')
                    return code;
                }}
            >
                <p>Loading...</p>
            </SVG>
        </ElementWrapper>
    );
}

export default Graphic;
