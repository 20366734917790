import log from 'cslog'
import { getContent } from '../../../dynamic/parser'

const QVarElementView = ({ attributes, children, element, style, dydb }) => {
  log.d(element, 'QVarElemet element')
  log.d(children, 'children in QVarElementView')
  log.d(attributes, 'attributes in QVarElementView')
  log.d(style, 'style in QVarElementView')

  const content = getContent(dydb, {
    id: element?.data?.id,
    field: element?.data?.field
  })

  children.text = 'New Children text'

  return (
    <span style={style} {...attributes}>
      <span style={{ display: 'none' }}>{children}</span>
      {/* <span style={style} {...attributes} data-slate-mode='text'>
        <span data-slate-leaf='true'>
          <span data-slate-string='true'> */}
      {content}
      {/* </span>
        </span>
      </span> */}
    </span>
  )
}

export default QVarElementView
