import WIDGETS_COMMON from "../common/widgets";
import { combineElements } from "../../../parser/allparser";

const RESPONSIVE = {
    iconList: {
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    navMenu: {
        group: "complex",
        states: {
            menuItem: ["normal", "hover", "selected"],
            submenuItem: ["normal", "hover"],
        },
        styleGroup: {
            menuItem: {
                fontSize: 16,
                fontWeight: "normal",
                color: "black",
                // activeColor: {
                //     type: "config",
                //     value: "accentColor",
                // },
                backgroundColor: "transparent",
                padding: {
                    type: "single",
                    params: 10,
                    value: "10px",
                },
                fontFamily: {
                    type: "config",
                    value: "primaryFont",
                },
            },
            menuItem__hover: {},
            menuItem__selected: {
                color: "black",
                backgroundColor: "transparent",
                fontWeight: "bold",
            },
            // activeMenuItem: {
            //     color: {
            //         type: "config",
            //         value: "accentColor",
            //     },
            //     backgroundColor: "transparent",
            //     fontWeight: "bold",
            // },
            submenu: {
                backgroundColor: "#eee",
                boxShadow: {
                    type: "none",
                },
                borderRadius: {
                    type: "single",
                    params: 0,
                    value: "0px",
                },
            },
            submenuItem: {
                fontSize: 14,
                color: "black",
                backgroundColor: "white",
                // bold: "true",
                fontWeight: "normal",
                padding: {
                    type: "multiple",
                    params: {
                        cside: "top",
                        top: 9,
                        bottom: 9,
                        left: 18,
                        right: 18,
                    },
                    value: "9px 18px 9px 18px",
                },
            },
            submenuItem__hover: {
                color: "black",
                backgroundColor: "#eee",
            },
        },
        hover: null,
        mobileGroup: {
            burgerButton: {
                fontSize: 30,
                color: "white",
                backgroundColor: "transparent",
                padding: {
                    type: "single",
                    params: 10,
                    value: "10px",
                },
                textAlign: "right",
            },
        },
        input: {
            menuHeight: 40,
            menuItemAnimation: "qnav-full",
            burgerIcon: {
                params: {
                    type: "fa",
                    name: "bars",
                },
                value: "fa fa-bars",
            },
            alignItems: "flex-end",
            submenuOrientation: "v",
            // itemGap: 50,
        },
        itype: {
            items: "navListData",
            menuHeight: "standardRange",
            // itemGap: "standardRange",
        },
    },
    verticalMenu: {
        styleGroup: {},
        mobileGroup: {
            menu: {
                backgroundColor: "transparent",
            },
            menuItem: {
                fontSize: 17,
                fontWeight: "normal",
                color: "black",
                padding: {
                    type: "multiple",
                    params: {
                        cside: "left",
                        top: 5,
                        left: 18,
                        right: 5,
                        bottom: 5,
                    },
                    value: "5px 5px 5px 18px",
                },
                fontFamily: {
                    type: "config",
                    value: "primaryFont",
                },
            },
            submenu: {
                backgroundColor: "rgba(0,0,0,0.1)",
            },
            submenuItem: {
                fontSize: 17,
                fontWeight: "normal",
                color: "black",
                bold: "normal",
                padding: {
                    type: "multiple",
                    params: {
                        cside: "left",
                        top: 5,
                        left: 60,
                        right: 5,
                        bottom: 5,
                    },
                    value: "5px 5px 5px 60px",
                },
            },
        },
        hover: null,
        mobileGroup2: {},
        input: {
            // itemGap: 50,
        },
        itype: {
            items: "navListData",
            // itemGap: "standardRange",
        },
    },
    countdown: {},
    codeview: {},
    viewer3d: {},
    audioPlayer: {},
    loopBuilder: {
        style: {
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
        },
        input: {
            dataSource: null,
            cardsPerRow: 3,
            gap: 10,
            verticalAlign: "stretch",
        },
    },
    filters: {
        style: {
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
        },
        input: {},
    },
    qrCode: {
        style: {
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
        },
        input: {
            canvasSize: {
                type: "medium",
                params: 250,
                value: "250px",
            },
            alignImage: "flex-start",
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    gallery: {},
    map: {
        input: {
            height: 300,
        },
    },
    imageCarousel: {
        input: {
            sliderSize: {
                type: "medium",
                params: 250,
                value: "250px",
            },
        },
    },
    typingText: {
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    form: {},
    popupForm: {},
    search: {},
    breadcrumb: {},
    pagination: {},
    formContact: {},
    rating: {},
    socialIcons: {},
    googleCalendar: {},
    iframe: {
        input: {
            canvasSize: {
                type: "full",
                params: 0,
                value: "100%",
            },
            height: 300,
            alignVideo: "center",
        },
    },
    slider: {
        style: {},
        hover: {},
        mobile: {},
        input: {},
    },
    pdfViewer: {},
    tabs: {},
    table: {},
};

const WIDGETS_RESPONSIVE = combineElements(WIDGETS_COMMON, RESPONSIVE);
export default WIDGETS_RESPONSIVE;
