export const OPTIONS = {
	headingTag: [
		["H1", "h1"],
		["H2", "h2"],
		["H3", "h3"],
		["H4", "h4"],
		["H5", "h5"],
		["H6", "h6"],
	],
	slideEffect: [
		["Slide", "slide"],
		["Fade", "fade"],
	],
	columnCount: [
		[1, 1],
		[2, 2],
		[3, 3],
		[4, 4],
	],
	totalStars: [
		[5, 5],
		[10, 10],
	],
	alignItems: [
		["flex-start", "align left"],
		["center", "align center"],
		["flex-end", "align right"],
		["space-between", "align justify"],
	],
	textAlign: [
		["left", "align left"],
		["center", "align center"],
		["right", "align right"],
		["justify", "align justify"],
	],
	buttonAlign: [
		["flex-start", "align left"],
		["center", "align center"],
		["flex-end", "align right"],
		["stretch", "align justify"],
	],
	alignImage: [
		["flex-start", "align left"],
		["center", "align center"],
		["flex-end", "align right"],
	],
	alignment: [
		["flex-start", "align left"],
		["center", "align center"],
		["flex-end", "align right"],
	],
	alignVideo: [
		["flex-start", "align left"],
		["center", "align center"],
		["flex-end", "align right"],
	],
	alignList: [
		["flex-start", "align left"],
		["center", "align center"],
		["flex-end", "align right"],
	],
	alignItem: [
		["flex-start", "align left"],
		["center", "align center"],
		["flex-end", "align right"],
	],
	imageFit: [
		["Cover", "cover"],
		["Contain", "contain"],
		["Fill", "fill"]
	],
	frameType: [
		["none", "dont"],
		["solid", "square"],
		["frame", "square outline"],
	],
	alignChilds: [
		["flex-start", "long arrow alternate up"],
		["center", "dot circle outline"],
		["flex-end", "long arrow alternate down"],
		["stretch", "arrows alternate vertical"],
	],
	verticalAlign: [
		["start", "long arrow alternate up"],
		["center", "dot circle outline"],
		["end", "long arrow alternate down"],
		["stretch", "arrows alternate vertical"],
	],
	orientation: [
		["h", "ellipsis horizontal"],
		["v", "ellipsis vertical"],
	],
	submenuOrientation: [
		["h", "ellipsis horizontal"],
		["v", "ellipsis vertical"],
	],
	theme: [
		["dark", "square"],
		["light", "square outline"],
	],
	language: [
		["Python", "python"],
		["JSX", "jsx"],
		["CSS", "css"],
	],
	optionsMode: [
		["Tabs", "tabs"],
		["Dropdown", "dropdown"],
	],
	codeEditorTheme: [
		["Dracula", "dracula"],
		["Night Owl", "nightOwl"],
		["Github", "github"],
		["Ocianic Next", "oceanicNext"],
		["Shade Of Purple", "shadesOfPurple"],
	],
	lineStyle: [
		["Solid", "solid"],
		["Dashed", "dashed"],
		["Dotted", "dotted"],
		["Rounded", "rounded"],
	],
	hoverAnimation: [
		["None", "qureal-none"],
		["Grow", "qureal-grow"],
		["Shrink", "qureal-shrink"],
		["Pulse", "qureal-pulse"],
		["Push", "qureal-push"],
		["Pop", "qureal-pop"],
		["Float", "qureal-float"],
		["Bob", "qureal-bob"],
		["Forward", "qureal-forward"],
		["Grow Shadow", "qureal-grow-shadow"],
	],
	voiceLangs: [
		["English", "en-IN"],
		["Hindi", "hi-IN"],
		["Marathi", "mr-IN"],
		["Bengali", "bn-IN"],
		["Gujarati", "gu-IN"],
		["Kannada", "kn-IN"],
		["Malayalam", "ml-IN"],
		["Tamil", "ta-IN"],
		["Telugu", "te-IN"],
	],
	paginationStyle: [
		["Buttlets", "bullets"],
		["Dynamic Bullets", "dynamicBullets"],
		["Fraction", "fraction"],
		["Progressbar", "progressbar"],
		["None", null],
	],
	swipeDirection: [
		["Horizontal", "horizontal"],
		["Vertical", "vertical"],
	],
	slideTransition: [
		// ["Slide", "slide"],
		// ["Cube", "cube"],
		// ["Fade", "fade"],
	],
	sliderEffect: [
		["Slide", "slide"],
		["Cube", "cube"],
		["Flip", "flip"],
		["Fade", "fade"],
		["Cards", "cards"],
		["Creative", "creative"]
	],
	background3d: [
		["Sunset", "sunset"],
		["Dawn", "dawn"],
		["Night", "night"],
		["Warehouse", "warehouse"],
		["Forest", "forest"],
		["Apartment", "apartment"],
		["Studio", "studio"],
		["City", "city"],
		["Park", "park"],
		["Lobby", "lobby"],
	],
	buttonType: [
		["Text", "text"],
		["Icon", "icon"],
		["Text + Icon", "text_icon"],
		["Link", "link"],
	],
	galleryLayout: [
		["Rows", "rows"],
		["Columns", "columns"],
		["Masonry", "masonry"],
	],
	autoCustom: [
		["Auto", "home"],
		["Custom", "columns"],
	],
	menuItemAnimation: [
		["Full", "qnav-full"],
		["Line Below", "qnav-lineBelow"],
		["Bubble", "qnav-bubble"],
		["Border", "qnav-border"],
		["SlideFill", "qnav-parda"],
	],
	mapMode: [
		["Roadmap", "r"],
		["Satellite", "k"],
		["Terrain", "p"],
	],
	groupMode: [
		["Stacked", "stacked"],
		["Grouped", "grouped"],
	],
	signup_with: [
		["Email", "email"],
		["Mobile", "mobile"],
	],
	filterInterface: [
		["Chips", "chips"],
		["Dropdown", "dropdown"],
		["Menu Vertical", "menu_v"],
		["Menu Horizontal", "menu_h"],
		["Checkbox", "checkbox"],
	],
};
