const WIDGETS_COMMON = {
    iconList: {
        styleGroup: {
            iconList: {
                // margin: {
                //     type: "single",
                //     params: 20,
                //     value: "20px",
                // },
                padding: {
                    type: "single",
                    params: 0,
                    value: "0px",
                },
            },
            icon: {
                color: "black",
            },
            text: {
                color: "black",
                // fontFamily: `Arial, Helvetica, sans-serif`,
                fontFamily: {
                    type: "config",
                    value: "secondryFont",
                },
            },
        },
        mobileGroup: {
            iconList: {},
            icon: {},
            text: {},
        },
        input: {
            items: [
                {
                    icon: {
                        params: {
                            type: "fa",
                            name: "coffee",
                        },
                        value: "fa fa-coffee",
                    },
                    text: "Item 1",
                    action: {
                        type: "none",
                    },
                },
                {
                    icon: {
                        params: {
                            type: "fa",
                            name: "coffee",
                        },
                        value: "fa fa-coffee",
                    },
                    text: "Item 2",
                    action: {
                        type: "none",
                    },
                },
                {
                    icon: {
                        params: {
                            type: "fa",
                            name: "coffee",
                        },
                        value: "fa fa-coffee",
                    },
                    text: "Item 3",
                    action: {
                        type: "none",
                    },
                },
            ],
            itemGap: 1.5,
            iconSize: {
                type: "mini",
                params: 15,
                value: "15px",
            },
            orientation: "v",
            // alignList: "flex-start",
        },
    },
    socialIcons: {
        styleGroup: {
            iconList: {
                padding: {
                    type: "single",
                    params: 0,
                    value: "0px",
                },
            },
            icon: {
                color: {
                    type: "config",
                    value: "secondryColor",
                },
                background: {
                    type: "simple",
                    params: "#E0E1E2",
                    value: "#E0E1E2",
                },
                textShadow: {
                    type: "none",
                    params: {},
                    value: "none",
                },
                boxShadow: {
                    type: "none",
                    params: {},
                    value: "none",
                },
            },
        },
        mobileGroup: {
            iconList: {},
            icon: {},
        },
        input: {
            socialItems: [
                ["facebook", "#1877f2"],
                ["instagram-square", "#c32aa3"],
                ["twitter", "#1da1f2"],
                ["linkedin", "#0a66c2"],
                ["youtube", "#ff0000"],
            ].map((s) => {
                return {
                    icon: {
                        params: {
                            type: "fab",
                            name: s[0],
                        },
                        value: `fab fa-${s[0]}`,
                    },
                    color: s[1],
                    action: {
                        type: "link",
                        params: {
                            link: "https://qureal.com",
                            newTab: true,
                        },
                    },
                };
            }),
            itemGap: 1.7,
            iconGap: 2,
            iconSize: {
                type: "small",
                params: 24,
                value: "24px",
            },
            frameType: "none",
            frameShape: { type: "circle", value: "circle" },
            alignItems: "flex-start",
            orientation: "h",
            // alignList: "flex-start",
        },
    },
    googleCalendar: {
        css: null,
        fixStyle: {
            boxSizing: "border-box",
            maxWidth: "100%",
        },
        style: {
            transition: "0.3s",
            borderRadius: {
                type: "single",
                params: 0,
                value: 0,
            },
            borderStyle: "none",
            borderColor: "black",
            borderWidth: {
                type: "single",
                params: 5,
                value: 5,
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            googleCalendarURL: {
                params: "www.youtube.com/embed/rUWxSEwctFU",
                value: "rUWxSEwctFU",
            },
        },
    },
    iframe: {
        css: null,
        fixStyle: {
            boxSizing: "border-box",
            maxWidth: "100%",
        },
        style: {
            transition: "0.3s",
            borderRadius: {
                type: "single",
                params: 0,
                value: 0,
            },
            borderStyle: "none",
            borderColor: "black",
            borderWidth: {
                type: "single",
                params: 5,
                value: 5,
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            URL: {
                params: "www.youtube.com/embed/rUWxSEwctFU",
                value: "https://www.youtube.com/embed/rUWxSEwctFU",
            },
        },
    },
    rating: {
        styleGroup: {
            ratingBar: {
                // margin: {
                //     type: "single",
                //     params: 20,
                //     value: "20px",
                // },
                padding: {
                    type: "single",
                    params: 10,
                    value: "10px",
                },
            },
            icon: {
                filledColor: "#FFA000",
                remainingColor: "#D9D9D9",
                strokeColor: "black",
                strokeWidth: 0,
            },
            text: {
                color: "black",
                // fontFamily: `Arial, Helvetica, sans-serif`,
                fontFamily: {
                    type: "config",
                    value: "secondryFont",
                },
            },
        },
        mobileGroup: {
            ratingBar: {},
            iconFilled: {},
            iconBlank: {},
            text: {},
        },
        input: {
            totalStars: 5,
            filledStars: 2.5,
            iconSource: {
                params: {
                    type: "fa",
                    name: "star",
                },
                value: "fa fa-star",
            },
            iconSize: {
                type: "medium",
                params: 40,
                value: "40px",
            },
            itemGap: 1.5,
            // orientation: "v",
            alignItems: "flex-start",
        },
    },
    loopBuilder: {
        style: {},
        mobile: {},
        input: {},
    },
    filters: {
        mobile: {},
        input: {
            filterConfigs: {
                allow_multiple: false,
            },
            filterInterface: "chips", // chips, dropdown, checkbox,menu
            allowMultiple: true,
            alignItems: "flex-start",
            // fields:
        },
        states: ["normal", "hover", "selected"],
        style: {
            color: "black",
            background: {
                type: "simple",
                params: "#ccc5",
                value: "#ccc5",
            },
            fontFamily: {
                type: "config",
                value: "primaryFont",
            },
            borderStyle: "none",
            borderColor: "black",
            borderWidth: {
                type: "single",
                params: 5,
                value: 5,
            },
            padding: {
                type: "multiple",
                params: {
                    cside: "top",
                    top: 9,
                    bottom: 9,
                    left: 18,
                    right: 18,
                },
                value: "9px 18px 9px 18px",
            },
            borderRadius: {
                type: "single",
                params: 15,
                value: 15,
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
            transition: "0.3s",
        },
        style__hover: {},
        style__selected: {
            color: "white",
            background: {
                type: "config",
                value: "secondryColor",
            },
        },
    },
    gallery: {
        styleGroup: {
            gallery: {},
            image: {
                borderRadius: {
                    type: "single",
                    params: 0,
                    value: 0,
                },
                boxShadow: {
                    type: "none",
                    params: {},
                    value: "none",
                },
            },
        },
        mobileGroup: {
            gallery: {},
            image: {},
        },
        input: {
            photos: [],
            spacing: 15,
            galleryLayout: "rows",
            lightbox: true,
        },
    },
    imageCarousel: {
        styleGroup: {
            carousel: {
                // margin: {
                //     type: "single",
                //     params: 20,
                //     value: "20px",
                // },
                padding: {
                    type: "single",
                    params: 0,
                    value: "0px",
                },
                arrowColor: "white",
                paginationColor: "white",
            },
            image: {
                borderRadius: {
                    type: "single",
                    params: 0,
                    value: 0,
                },
                boxShadow: {
                    type: "none",
                    params: {},
                    value: "none",
                },
            },
        },
        mobileGroup: {
            gallery: {},
            image: {},
        },
        input: {
            photos: [],
            slidesPerView: 1,
            spacing: 15,
            paginationStyle: "bullets",
            showArrow: true,
            autoPlay: false,
            pauseOnHover: false,
            freeMode: false,
            effect: "slide",
            slideEffect: "slide",
            lightbox: true,
            imageFit: "contain",
            // autoPlaySlides: {
            //     enable: true,
            //     delay: 3000,
            //     pauseOnHover: true,
            // },
        },
        itype: {
            photos: "imgArray",
            spacing: "standardRange",
            galleryLayout: "selectOption",
        },
        dataFields: {
            items: "photos",
        },
    },
    navMenu: {},
    verticalMenu: {},
    countdown: {
        style: {
            // margin: {
            //     type: "single",
            //     params: 0,
            //     value: 0,
            // },
            padding: {
                type: "single",
                params: 10,
                value: 10,
            },
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            borderRadius: {
                type: "single",
                params: 0,
                value: 0,
            },
            border: {
                type: "none",
                params: {
                    width: 1,
                    color: "black",
                },
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            size: 25,
            // alignItem: "center",
            theme: "dark",
        },
        itype: {
            size: "standardRange",
            // alignItem: "optionRowIcon",
            theme: "optionRowIcon",
        },
        deps: {
            js: "https://pbutcher.uk/flipdown/js/flipdown/flipdown.js",
            css: "https://pbutcher.uk/flipdown/css/flipdown/flipdown.css",
        },
    },
    codeview: {
        style: {
            // margin: {
            //     type: "single",
            //     params: 0,
            //     value: 0,
            // },
            borderRadius: {
                type: "single",
                params: 0,
                value: 0,
            },
        },
        hover: {},
        mobile: {},
        input: {
            code: `(function someDemo() {
    var test = "Hello World!";
    console.log(test);
})();
  
return () => <App />;`,
            language: "jsx",
            fontSize: 30,
            padding: 20,
            theme: "dark",
            codeEditorTheme: "nightOwl",
        },
        itype: {
            code: "text",
            fontSize: "standardRange",
            language: "selectOption",
            padding: "standardRange",
            codeEditorTheme: "selectOption",
        },
        exclude: ["code"],
        dataFields: {
            code: "text",
            language: "text",
        },
    },
    viewer3d: {
        style: {
            // margin: {
            //     type: "single",
            //     params: 0,
            //     value: 0,
            // },
            padding: {
                type: "single",
                params: 10,
                value: 10,
            },
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            borderRadius: {
                type: "single",
                params: 0,
                value: 0,
            },
            border: {
                type: "none",
                params: {
                    width: 1,
                    color: "black",
                },
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            file: null,
            size: 25,
            background3d: "sunset",
        },
        itype: {
            file: "file3d",
            size: "standardRange",
            background3d: "selectOption",
        },
    },
    audioPlayer: {
        style: {
            padding: {
                type: "single",
                params: 10,
                value: 10,
            },
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            borderRadius: {
                type: "single",
                params: 0,
                value: 0,
            },
            border: {
                type: "none",
                params: {
                    width: 1,
                    color: "black",
                },
                value: "none",
            },
            boxShadow: {
                type: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            audioFile: null,
            autoPlay: false,
            loop: false,
            showJumpControls: true,
            showVolumeControls: true,
            showLoopButton: true,
            showProgressbar: true,
            showProgressBelow: false,
            themeColor: "#868686",
            timeTextColor: "#333",
            barColor: "#dddddd",
        },
        itype: {
            audioFile: "fileAudio",
            autoPlay: "boolean",
            loop: "boolean",
            showJumpControls: "boolean",
            showVolumeControls: "boolean",
            showLoopButton: "boolean",
            showProgressbar: "boolean",
            showProgressBelow: "boolean",
            themeColor: "color",
            timeTextColor: "color",
            barColor: "color",
        },
    },
    qrCode: {
        style: {
            borderRadius: {
                type: "single",
                params: 0,
                value: 0,
            },
            border: {
                type: "none",
                params: {
                    width: 1,
                    color: "black",
                },
                value: "none",
            },
            boxShadow: {
                type: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            content: "Qureal",
            gap: 10,

            color: "black",
            backgroundColor: "white",
        },
        itype: {
            content: "shorttext",
            gap: "standardRange",

            color: "color",
            backgroundColor: "color",
        },
    },
    map: {
        style: {
            borderRadius: {
                type: "single",
                params: 0,
                value: 0,
            },
            border: {
                type: "none",
                params: {
                    width: 1,
                    color: "black",
                },
                value: "none",
            },
            boxShadow: {
                type: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            pinnedLocation: "Marine drive",
            mapMode: "r",
            zoomLevel: 13,
        },
        itype: {
            content: "shorttext",
        },
    },
    typingText: {
        style: {
            color: {
                type: "config",
                value: "neutralColor",
            },
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            // fontFamily: `Arial, Helvetica, sans-serif`,
            fontFamily: {
                type: "config",
                value: "primaryFont",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            borderRadius: {
                type: "single",
                params: 3,
                value: 3,
            },
            textShadow: {
                type: "none",
                params: {},
                value: "none",
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            textSequence: [
                "Qureal Technologies",
                "We provide Platform as a Service",
                "Hello World",
            ],
            duration: 1000,
            fontSize: 20,
            lineHeight: 1.5,
            bold: true,
            uppercase: false,
            textAlign: "left",
            typeSpeed: 40,
            backSpeed: 50,
            startDelay: 0,
            backDelay: 20,
            showCursor: true,
            loop: true,
            shuffle: false,
            // color: "black",
            // backgroundColor: "white",
        },
    },
    form: {
        style: {
            color: {
                type: "config",
                value: "neutralColor",
            },
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            // fontFamily: `Arial, Helvetica, sans-serif`,
            fontFamily: {
                type: "config",
                value: "primaryFont",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            borderRadius: {
                type: "single",
                params: 3,
                value: 3,
            },
            textShadow: {
                type: "none",
                params: {},
                value: "none",
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            // emailTo: "",
            // successMessage: "Thanks for form submition",
            form: null,
            editRecord: {
                enable: false,
            },
            formInterface: "default", //default, horizontal, no-label
            submitButtonText: "Submitt",
            submittingButtonText: "Submitting form..",
            successMessage: "Record created successfully",
            fontSize: 14,
            bold: true,
            uppercase: false,
            textAlign: "left",
            actionOnSuccess: {
                type: "none",
            },
        },
    },
    popupForm: {
        styleGroup: {
            button: {
                color: "white",
                background: {
                    type: "config",
                    value: "secondryColor",
                },
                fontFamily: {
                    type: "config",
                    value: "primaryFont",
                },
                borderStyle: "none",
                borderColor: "black",
                borderWidth: {
                    type: "single",
                    params: 5,
                    value: 5,
                },
                padding: {
                    type: "multiple",
                    params: {
                        cside: "top",
                        top: 9,
                        bottom: 9,
                        left: 18,
                        right: 18,
                    },
                    value: "9px 18px 9px 18px",
                },
                borderRadius: {
                    type: "single",
                    params: 3,
                    value: 3,
                },
                boxShadow: {
                    type: "none",
                    params: {},
                    value: "none",
                },
                transition: "0.3s",
            },
            form: {
                color: {
                    type: "config",
                    value: "neutralColor",
                },
                background: {
                    type: "simple",
                    params: "transparent",
                    value: "transparent",
                },
                // fontFamily: `Arial, Helvetica, sans-serif`,
                fontFamily: {
                    type: "config",
                    value: "primaryFont",
                },
                padding: {
                    type: "single",
                    params: 10,
                    value: "10px",
                },
                borderRadius: {
                    type: "single",
                    params: 3,
                    value: 3,
                },
                textShadow: {
                    type: "none",
                    params: {},
                    value: "none",
                },
                boxShadow: {
                    type: "none",
                    params: {},
                    value: "none",
                },
            },
        },
        hover: {},
        mobileGroup: {
            button: {},
            form: {},
        },
        input: {
            buttonType: {
                type: "text",
                params: {
                    text: "Create New",
                },
            },
            buttonAlign: "flex-start",
            size: 16,
            form: null,
            editRecord: {
                enable: false,
            },
            fields_overwrite: "{}",
            formInterface: "default", //default, horizontal, no-label
            submitButtonText: "Submitt",
            submittingButtonText: "Submitting form..",
            successMessage: "Record created successfully",
            fontSize: 14,
            // lineHeight: 1.5,
            bold: true,
            uppercase: false,
            textAlign: "left",
            actionOnSuccess: {
                type: "none",
            },
        },
    },
    search: {
        style: {
            color: {
                type: "config",
                value: "neutralColor",
            },
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            // fontFamily: `Arial, Helvetica, sans-serif`,
            fontFamily: {
                type: "config",
                value: "primaryFont",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            borderRadius: {
                type: "single",
                params: 3,
                value: 3,
            },
            textShadow: {
                type: "none",
                params: {},
                value: "none",
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            dataSource: null,
            placeholder: "Search",
            minimumLetterToSearch: 4,
            searchLocally: false,
            fontSize: 14,
        },
    },
    breadcrumb: {
        style: {
            color: {
                type: "config",
                value: "neutralColor",
            },
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            // fontFamily: `Arial, Helvetica, sans-serif`,
            fontFamily: {
                type: "config",
                value: "primaryFont",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            borderRadius: {
                type: "single",
                params: 3,
                value: 3,
            },
            textShadow: {
                type: "none",
                params: {},
                value: "none",
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            breadcrumbItems: [
                { label: "Item 1", action: { type: "none" } },
                { label: "Item 2", action: { type: "none" } },
            ],
            fontSize: 16,
            gap: 10,
            divider: "/",
        },
    },
    pagination: {
        style: {
            color: {
                type: "config",
                value: "neutralColor",
            },
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            // fontFamily: `Arial, Helvetica, sans-serif`,
            fontFamily: {
                type: "config",
                value: "primaryFont",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            borderRadius: {
                type: "single",
                params: 3,
                value: 3,
            },
            textShadow: {
                type: "none",
                params: {},
                value: "none",
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            dataSource: null,
            recordsPerPage: 4,
            fontSize: 14,
            hideOnSinglePage: true,
        },
    },
    formContact: {
        style: {
            color: {
                type: "config",
                value: "neutralColor",
            },
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            // fontFamily: `Arial, Helvetica, sans-serif`,
            fontFamily: {
                type: "config",
                value: "primaryFont",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            borderRadius: {
                type: "single",
                params: 3,
                value: 3,
            },
            textShadow: {
                type: "none",
                params: {},
                value: "none",
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            emailTo: "",
            successMessage: "Thanks for form submition",
            fontSize: 20,
            lineHeight: 1.5,
            bold: true,
            uppercase: false,
            textAlign: "left",
        },
    },
    invisibleButton: {
        css: null,
        fixStyle: {
            boxSizing: "border-box",
            maxWidth: "100%",
        },
        style: {
            // color: "black",
            background: {
                type: "simple",
                params: "#b5b5b5",
                value: "#b5b5b5",
            },
            border: {
                type: "none",
                params: {
                    width: 1,
                    color: "black",
                },
                value: "none",
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
            transition: "0.3s",
        },
        hover: {},
        mobile: {},
        input: {
            frameShape: { type: "square", value: "square" },
            opacity: 0.2,
            action: {
                type: "none",
            },
        },
        itype: {},
        dataFields: {},
    },
    pdfViewer: {
        style: {
            padding: {
                type: "single",
                params: 0,
                value: "0px",
            },
            borderStyle: "none",
            borderColor: "black",
            borderWidth: {
                type: "single",
                params: 5,
                value: 5,
            },
            borderRadius: {
                type: "single",
                params: 3,
                value: 3,
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            pdfFile: null,
            zoomLevel: 10,
            duration: 1000,
            alignment: "center",
        },
    },
    tabs: {
        group: "complex",
        states: {
            tabButton: ["normal", "hover", "selected"],
        },
        styleGroup: {
            tabButton: {
                color: {
                    type: "config",
                    value: "naturalColor",
                },
                background: "#ddd",
                fontFamily: {
                    type: "config",
                    value: "primaryFont",
                },
                borderStyle: "solid",
                borderColor: "#777",
                borderWidth: {
                    type: "single",
                    params: 1,
                    value: 1,
                },
                padding: {
                    type: "multiple",
                    params: {
                        cside: "top",
                        top: 9,
                        bottom: 9,
                        left: 18,
                        right: 18,
                    },
                    value: "9px 18px 9px 18px",
                },
                margin: {
                    type: "single",
                    params: 0,
                    value: "0px",
                },
                borderRadius: {
                    type: "single",
                    params: 0,
                    value: 0,
                },
                boxShadow: {
                    type: "none",
                    params: {},
                    value: "none",
                },
                transition: "0.3s",
            },
            tabButton__selected: {
                color: "white",
                background: {
                    type: "config",
                    value: "secondryColor",
                },
            },
        },
        hover: null,
        mobileGroup: {
            tabButton: {},
        },
        // onInit: {
        // },
        childs: [],
        input: {
            tabs: [],
            optionsMode: "tabs",
            orientation: "h",
            flipSide: false,
            alignItems: "flex-start",
            textAlign: "right",
        },
        itablet: {
            optionsMode: "dropdown",
            orientation: "h",
            alignItems: "center",
        },
    },
    table: {
        group: "complex",
        states: {
            row: ["normal", "hover", "selected"],
        },
        styleGroup: {
            table: {
                background: "cyan",
            },
            row: {
                color: {
                    type: "config",
                    value: "naturalColor",
                },
                background: "#ddd",
                fontFamily: {
                    type: "config",
                    value: "primaryFont",
                },
                borderStyle: "solid",
                borderColor: "#777",
                borderWidth: {
                    type: "single",
                    params: 1,
                    value: 1,
                },
                padding: {
                    type: "multiple",
                    params: {
                        cside: "top",
                        top: 9,
                        bottom: 9,
                        left: 18,
                        right: 18,
                    },
                    value: "9px 18px 9px 18px",
                },
                margin: {
                    type: "single",
                    params: 0,
                    value: "0px",
                },
                borderRadius: {
                    type: "single",
                    params: 3,
                    value: 3,
                },
                boxShadow: {
                    type: "none",
                    params: {},
                    value: "none",
                },
                transition: "0.3s",
            },
            row__hover: {
                color: "white",
                background: {
                    type: "config",
                    value: "secondryColor",
                },
            },
        },
        hover: null,
        mobileGroup: {
            tabButton: {},
        },
        // onInit: {
        // },
        childs: [],
        input: {
            tableData: {
                columns: [
                    { key: "name", name: "Name", editor: "text" }, //editor: false for disable
                    { key: "title", name: "Title", editor: "text" },
                ],
                rows: [
                    { name: "Mohan", title: "Example" },
                    { name: "Rajesh", title: "Demo" },
                ],
            },
        },
    },
};

export default WIDGETS_COMMON;
