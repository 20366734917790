const WIDGETS = {
    iconList: {
        id: "iconList",
        etype: "widgets",
        type: "iconList",
        group: "complex",
        pgroup: "pro",
        name: "Icon List",
        extends: {
            type: "default",
            name: "iconList",
        },

        dims: {
            w: 120,
            h: 150,
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    gallery: {
        id: "gallery",
        etype: "widgets",
        type: "gallery",
        group: "complex",
        pgroup: "pro",
        name: "Gallery",
        extends: {
            type: "default",
            name: "gallery",
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    loopBuilder: {
        id: "loopBuilder",
        etype: "widgets",
        type: "loopBuilder",
        group: "complex",
        pgroup: "pro",
        name: "Loop Builder",
        extends: {
            type: "default",
            name: "loopBuilder",
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    rating: {
        id: "rating",
        etype: "widgets",
        type: "rating",
        group: "complex",
        pgroup: "pro",
        name: "Rating",
        extends: {
            type: "default",
            name: "rating",
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    filters: {
        id: "filters",
        etype: "widgets",
        type: "filters",
        group: "basic",
        pgroup: "pro",
        name: "Filters",
        extends: {
            type: "default",
            name: "filters",
        },
        css: null,
        style: {},
        hover: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    socialIcons: {
        id: "socialIcons",
        etype: "widgets",
        type: "socialIcons",
        group: "complex",
        pgroup: "pro",
        name: "Social Icons",
        extends: {
            type: "default",
            name: "socialIcons",
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    imageCarousel: {
        id: "imageCarousel",
        etype: "widgets",
        type: "imageCarousel",
        group: "complex",
        pgroup: "pro",
        name: "Image Carousel",
        extends: {
            type: "default",
            name: "imageCarousel",
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    slider: {
        id: "slider",
        etype: "widgets",
        type: "slider",
        group: "complex",
        pgroup: "pro",
        name: "Slider",
        extends: {
            type: "default",
            name: "slider",
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    countdown: {
        id: "countdown",
        etype: "widgets",
        type: "countdown",
        group: "basic",
        pgroup: "pro",
        name: "Countdown",
        extends: {
            type: "default",
            name: "countdown",
        },
        css: null,

        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    codeview: {
        id: "codeview",
        etype: "widgets",
        type: "codeview",
        group: "basic",
        // group: "widgets",
        pgroup: "pro",
        name: "Code View",
        extends: {
            type: "default",
            name: "codeview",
        },
        css: null,

        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    viewer3d: {
        id: "viewer3d",
        etype: "widgets",
        type: "viewer3d",
        group: "basic",
        pgroup: "pro",
        name: "3D Viewer",
        extends: {
            type: "default",
            name: "viewer3d",
        },
        css: null,

        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    audioPlayer: {
        id: "audioPlayer",
        etype: "widgets",
        type: "audioPlayer",
        group: "basic",
        pgroup: "pro",
        name: "Audio Player",
        extends: {
            type: "default",
            name: "audioPlayer",
        },
        css: null,

        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    qrCode: {
        id: "qrCode",
        etype: "widgets",
        type: "qrCode",
        group: "basic",
        pgroup: "free",
        name: "QR Code",
        css: null,
        extends: {
            type: "default",
            name: "qrCode",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    map: {
        id: "map",
        etype: "widgets",
        type: "map",
        group: "basic",
        pgroup: "free",
        name: "Google Map",
        css: null,
        extends: {
            type: "default",
            name: "map",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
    },
    typingText: {
        id: "typingText",
        etype: "widgets",
        type: "typingText",
        group: "basic",
        pgroup: "free",
        name: "Typing Text",
        css: null,
        extends: {
            type: "default",
            name: "typingText",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    googleCalendar: {
        id: "googleCalendar",
        etype: "widgets",
        type: "googleCalendar",
        group: "basic",
        pgroup: "free",
        name: "Google Calendar",
        css: null,
        extends: {
            type: "default",
            name: "googleCalendar",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    iframe: {
        id: "iframe",
        etype: "widgets",
        type: "iframe",
        group: "basic",
        pgroup: "free",
        name: "iFrame",
        css: null,
        extends: {
            type: "default",
            name: "iframe",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    form: {
        id: "form",
        etype: "widgets",
        type: "form",
        group: "basic",
        pgroup: "free",
        name: "Form",
        css: null,
        extends: {
            type: "default",
            name: "form",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {
            successMessage: "Form submitted successfully",
        },
        itype: {},
        itablet: {},
        imobile: {},
    },
    search: {
        id: "search",
        etype: "widgets",
        type: "search",
        group: "basic",
        pgroup: "free",
        name: "Search",
        css: null,
        extends: {
            type: "default",
            name: "search",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    pagination: {
        id: "pagination",
        etype: "widgets",
        type: "pagination",
        group: "basic",
        pgroup: "free",
        name: "Pagination",
        css: null,
        extends: {
            type: "default",
            name: "pagination",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    breadcrumb: {
        id: "breadcrumb",
        etype: "widgets",
        type: "breadcrumb",
        group: "basic",
        pgroup: "free",
        name: "Breadcrumb",
        css: null,
        extends: {
            type: "default",
            name: "breadcrumb",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    popupForm: {
        id: "popupForm",
        etype: "widgets",
        type: "popupForm",
        group: "complex",
        pgroup: "free",
        name: "Popup Form",
        css: null,
        extends: {
            type: "default",
            name: "popupForm",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {
            successMessage: "Form submitted successfully",
        },
        itype: {},
        itablet: {},
        imobile: {},
    },
    formContact: {
        id: "formContact",
        etype: "widgets",
        type: "formContact",
        group: "basic",
        pgroup: "free",
        name: "Contact Form",
        css: null,
        extends: {
            type: "default",
            name: "formContact",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {
            emailTo: "",
            successMessage: "Thanks for enquiry. We will contact you soon",
        },
        itype: {},
        itablet: {},
        imobile: {},
    },
    navMenu: {
        id: "navMenu",
        etype: "widgets",
        type: "navMenu",
        group: "complex",
        pgroup: "free",
        name: "Nav Menu",
        css: null,
        extends: {
            type: "default",
            name: "navMenu",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {
            navItems: [
                {
                    name: "Home",
                    action: {
                        type: "page",
                        params: {
                            name: "Home",
                            id: "home",
                        },
                        value: {
                            name: "Home",
                            id: "home",
                        },
                    },
                    children: [],
                },
                {
                    name: "About",
                    action: {
                        type: "page",
                        params: {
                            name: "About",
                            id: "about_us",
                        },
                        value: {
                            name: "About",
                            id: "about_us",
                        },
                    },
                    children: [],
                },
            ],
        },
        itype: {},
        itablet: {},
        imobile: {},
    },
    verticalMenu: {
        id: "verticalMenu",
        etype: "widgets",
        type: "verticalMenu",
        group: "complex",
        pgroup: "free",
        name: "Vertical Menu",
        css: null,
        extends: {
            type: "default",
            name: "verticalMenu",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {
            navItems: [
                {
                    name: "Home",
                    action: {
                        type: "page",
                        params: {
                            name: "Home",
                            id: "home",
                        },
                        value: {
                            name: "Home",
                            id: "home",
                        },
                    },
                    children: [],
                },
                {
                    name: "About",
                    action: {
                        type: "page",
                        params: {
                            name: "About",
                            id: "about_us",
                        },
                        value: {
                            name: "About",
                            id: "about_us",
                        },
                    },
                    children: [],
                },
            ],
            iconSource: {
                params: {
                    type: "fa",
                    name: "fa-angle-double-right",
                },
                value: "fa fa-angle-double-right",
            },
        },
        itype: {},
        itablet: {},
        imobile: {},
    },
    pageNumber: {
        id: "pageNumber",
        etype: "widgets",
        type: "pageNumber",
        group: "basics",
        pgroup: "free",
        name: "Page Number",
        extends: {
            type: "default",
            name: "pageNumber",
        },

        cstate: "normal",
        css: null,
        style: {},
        mobile: {},
        input: {},
        itype: {},
    },
    pdfViewer: {
        id: "pdfViewer",
        etype: "widgets",
        type: "pdfViewer",
        group: "basic",
        pgroup: "free",
        name: "PDF Viewer",
        css: null,
        extends: {
            type: "default",
            name: "pdfViewer",
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    tabs: {
        id: "tabs",
        etype: "widgets",
        type: "tabs",
        group: "complex",
        pgroup: "pro",
        name: "Tabs",
        extends: {
            type: "default",
            name: "tabs",
        },
        cstate: {
            tabButton: "normal",
        },
        dims: {
            w: 120,
            h: 150,
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    table: {
        id: "table",
        etype: "widgets",
        type: "table",
        group: "complex",
        pgroup: "pro",
        name: "Table",
        extends: {
            type: "default",
            name: "table",
        },
        cstate: {
            row: "normal",
        },
        dims: {
            w: 120,
            h: 150,
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
};

export default WIDGETS;
