/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createEditor } from 'slate'
import { Editable, Slate, withReact } from 'slate-react'
import SlateElement, { SlateElementView } from './SlateElement'
import Leaf from './Leaf'
import log from 'cslog'

const SlateViewer = ({
  id,
  input,
  keepFresh = true,
  padding = 0,
  dydb = {}
}) => {
  const [key, setKey] = useState(0)
  const editor = useMemo(() => withReact(createEditor()), [])
  const [value, setValue] = useState(input.html)
  const renderElement = useCallback(
    props => <SlateElementView {...props} dydb={dydb} />,
    [dydb]
  )
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  useEffect(() => {
    setValue(input.html)
    setKey(n => n + 1)
  }, [input.html])

  log.d(dydb, 'dydb in SlateViewer')

  return (
    <div
      css={css`
        .qslate p,
        .qslate li,
        .qslate blockquote {
          font-size: ${input.fontSize || 16}px;
          line-height: ${input.lineHeight * 100}%;
          letter-spacing: ${input.letterSpacing.value};
        }
        // span {
        //     border-radius: 3px;
        // }
      `}
    >
      <Slate editor={editor} value={value} key={keepFresh ? key : id}>
        <Editable
          readOnly
          placeholder='Click "Edit Content" to write...'
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          className='qslate'
          style={{
            padding: padding
          }}
        />
      </Slate>
    </div>
  )
}

export default SlateViewer
