/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import React from "react";
import { decodeStyle, parseStyle, SlateViewer } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";

function HtmlText({ id, db, gs }) {
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    let input = ps.input;

    let styles = decodeStyle(ps.style, gs);

    log.d(input.html, "Html in HtmlText");

    return (
        <ElementWrapper id={id}>
            <div
                css={{
                    columnWidth: "auto",
                    columnCount: input.columnCount,
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    "&:hover": decodeStyle(ps.hover, gs),
                    textAlign: input.textAlign,
                }}
            >
                <SlateViewer id={id} input={input} styles={styles} />
                {/* <QurealTextEditorBasic
                    id={`QQ${id}`}
                    // html={input.html}
                    html="Hello"
                    setHtml={handleChange}
                    onFocus={() => setSelectedElement(id)}
                    styles={styles}
                    active={selectedElement === id}
                /> */}
            </div>
        </ElementWrapper>
    );
}

export default HtmlText;
