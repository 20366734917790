import React, { Fragment, useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { useUploadPhoto } from "../actions/mutations/assets";
import ImageUploader from "./ImageUploader";
import log from "cslog";
import GraphicsTagDialog from "./GraphicsTagDialog";

function UploadButton({
	accept = "image/png, image/gif, image/jpeg",
	primary = false,
	compress = true,
	enable_tags = false,
	// upload_payload = {},
	getPayload = () => ({}),
	btn_label,
	fluid = false,
}) {
	const [uploaded_files, setUploadedFiles] = useState([]);
	const mutation = useUploadPhoto(data => {
		log.d(data, "Uploaded file");
		setUploadedFiles(old_ufs => ([...old_ufs, data]));
		setOpenImageTagger(true);
	});
	const [status, setStatus] = useState({});
	const [filesToUpload, setFilesToUpload] = useState([]);
	const [openImageUploader, setOpenImageUploader] = useState(false);
	const [openImageTagger, setOpenImageTagger] = useState(false);

	const uploadImage = (title, photo, name = null) => {
		log.d(photo, "Next to upload");
		// setUploading(true);
		const formD = new FormData();

		// formD.append("title", "_qhidden_upload_");
		// formD.append("hidden", true);

		const upload_payload = getPayload(photo) || {};
		log.d(upload_payload, "UPload payload");
		Object.keys(upload_payload).forEach((key) => {
			formD.append(key, upload_payload[key]);
		});

		if (name) {
			formD.append("file", photo, name);
		} else {
			formD.append("file", photo);
		}

		log.d(formD, "Form Data");
		mutation.mutate(formD);
	};

	const uploadImages = (images) => {
		log.d(images, "Uploading all");
		images.forEach((photo) => {
			if (photo) {
				// if (typeof photo === "object" && photo !== null) {
				uploadImage("Uploaded", photo);
				// }
			}
		});
	};

	log.d(mutation, "Upload Button Mutation");
	const onFile = (eve) => {
		console.log("Got Image");
		const photos = eve.target.files;
		if (photos.length > 0) {
			if (compress) {
				setFilesToUpload(photos);
				setOpenImageUploader(true);
			} else {
				uploadImages(Array.from(photos));
			}
		}
	};

	log.d(uploaded_files, "Uploaded Files in Upload Button");

	return (
		<Fragment>
			<Button
				icon
				// primary
				onClick={(eve) => {
					eve.preventDefault();
					document.getElementById("photo-in-zzz").click();
				}}
				// loading={uploading}
				loading={mutation.isLoading}
				labelPosition={btn_label ? "left" : null}
				fluid={fluid}
				primary={primary}
			>
				<Icon name="upload" />
				{btn_label}
			</Button>

			{compress && (
				<ImageUploader
					open={openImageUploader}
					setOpen={(value) => {
						setOpenImageUploader(value);
						if (!value) {
							setFilesToUpload([]);
						}
					}}
					photos={filesToUpload}
					uploadPhotos={uploadImages}
				/>
			)}
			<input
				type="file"
				// accept="image/*"
				accept={accept}
				multiple="multiple"
				name="photo"
				onChange={onFile}
				id="photo-in-zzz"
				hidden
			/>

			{enable_tags && uploaded_files?.length > 0 &&
				<GraphicsTagDialog
					// key={uploaded_files?.length}
					uploaded_files={uploaded_files}
					open={openImageTagger}
					setOpen={setOpenImageTagger}
				/>}
		</Fragment>
	);
}

export default UploadButton;
