/** @jsxImportSource @emotion/react */
import { Accordion, Icon, Input } from "semantic-ui-react";
// import log from "cslog";
import log from "cslog";
import { useDrag } from "react-dnd";
import Fuse from "fuse.js";

import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { EDITOR_ICONS } from "./data/extra/editorIconData";
import { editorTypeState } from "./db/editorConfig";
import styles from "./styles/ComponentPanel.module.scss";
import { additionalElementsState, additionPanelsState } from "./db/storeDb";
import { useLongPress } from "use-long-press";
import {
	ELEMENTS as ELEMENTS_ABS,
	PANEL as PANEL_ABS,
} from "./data/elements/absolute/elements";
import {
	ELEMENTS as ELEMENTS_RES,
	PANEL as PANEL_RES,
} from "./data/elements/responsive/elements";
import useMedia from "react-use/lib/useMedia";
import { useMemo, useState } from "react";
import DataComponents from "./csv/DataComponents";

export function ElementDragging({ item }) {
	const icon =
		item.type in EDITOR_ICONS
			? EDITOR_ICONS[item.type]
			: EDITOR_ICONS["header"];
	return (
		<div
			className={styles.panelItem}
			style={{
				border: `1px solid #dddd`,
				width: "130px",
				boxShadow:
					" 0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)",
				opacity: 0.9,
			}}
			onClick={() => log.d("Clicked")}
		>
			{item.pgroup === "pro" && (
				<Icon
					className={styles.panelItemIcon}
					name="chess king"
				// color="yellow"
				/>
			)}
			<svg xmlns="http://www.w3.org/2000/svg" viewBox={icon.vb}>
				<title>Element</title>
				<path d={icon.path} strokeMiterlimit="10" strokeWidth="9" />
			</svg>

			{item.name}
		</div>
	);
}

const ElementResponsive = ({ item, index }) => {
	// log.d(item, "Item in er");
	const [{ opacity, isDragging }, dragRef] = useDrag(
		() => ({
			type: item.group || "primary",
			item: { data: item },
			collect: (monitor) => ({
				opacity: monitor.isDragging() ? 0.5 : 1,
				isDragging: !!monitor.isDragging(),
			}),
		}),
		[]
	);

	const icon =
		item.type in EDITOR_ICONS
			? EDITOR_ICONS[item.type]
			: EDITOR_ICONS["header"];
	return (
		<div
			className={styles.panelItem}
			ref={dragRef}
			style={{
				// border: `1px solid ${isDragging ? "grey" : "white"}`,
				border: `1px solid ${isDragging ? "red" : "white"}`,
			}}
			onClick={() => log.d("Clicked")}
		>
			{item.pgroup === "pro" && (
				<Icon
					className={styles.panelItemIcon}
					name="chess king"
				// color="yellow"
				/>
			)}
			<svg xmlns="http://www.w3.org/2000/svg" viewBox={icon.vb}>
				<title>Element</title>
				<path d={icon.path} strokeMiterlimit="10" strokeWidth="9" />
			</svg>

			{item.name}
		</div>
	);
};

const ElementMobile = ({ item, index }) => {
	const [{ opacity, isDragging }, dragRef] = useDrag(
		() => ({
			type: item.group || "primary",
			item: { data: item },
			collect: (monitor) => ({
				opacity: monitor.isDragging() ? 0.5 : 1,
				isDragging: !!monitor.isDragging(),
			}),
		}),
		[]
	);

	const icon =
		item.type in EDITOR_ICONS
			? EDITOR_ICONS[item.type]
			: EDITOR_ICONS["header"];
	return (
		<div
			className={styles.panelItem}
			ref={dragRef}
			style={{
				// border: `1px solid ${isDragging ? "grey" : "white"}`,
				border: `1px solid ${isDragging ? "red" : "white"}`,
			}}
		>
			{item.pgroup === "pro" && (
				<Icon
					className={styles.panelItemIcon}
					name="chess king"
				// color="yellow"
				/>
			)}
			<svg xmlns="http://www.w3.org/2000/svg" viewBox={icon.vb}>
				<title>Element</title>
				<path d={icon.path} strokeMiterlimit="10" strokeWidth="9" />
			</svg>

			{item.name}
		</div>
	);
};

const ElementAbsolute = ({ item, index }) => {
	const isDragging = false;

	const icon =
		item.type in EDITOR_ICONS
			? EDITOR_ICONS[item.type]
			: EDITOR_ICONS["header"];
	return (
		<div
			className={classNames(styles.panelItem, "droppable-element")}
			draggable={true}
			unselectable="on"
			onDragStart={(e) => {
				e.dataTransfer.setData(
					"text/plain",
					JSON.stringify({
						type: item.type,
						elementGroup: item.elementGroup,
					})
				);
				// e.stopPropagation();
			}}
			onClick={() => alert("Clicked")}
		>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox={icon.vb}>
				<title>Asset 97</title>
				<path d={icon.path} strokeMiterlimit="10" strokeWidth="9" />
			</svg>

			{item.name}
		</div>
	);
};

function ConponentSection({ panelItem, items, etype, isMobile }) {
	const [active, setActive] = useState(true);

	if (isMobile) {
		return (
			<div
				className={styles.panelSection}
				style={{
					paddingTop: "12px",
				}}
			>
				{items.map((item, index) => (
					// <ElementResponsive

					<ElementResponsive
						key={item.id}
						item={item}
						index={index}
					/>
				))}
			</div>
		);
	}
	return (
		<Accordion
			fluid
			styled
			key={panelItem.id}
			style={{ background: "#F3F3F3" }}
		>
			<Accordion.Title
				active={active}
				style={{ background: "#F3F3F3" }}
				onClick={() => setActive(!active)}
			>
				<Icon name="dropdown" />
				{panelItem.name}
			</Accordion.Title>
			<Accordion.Content active={active}>
				{etype.layout === "absolute" ? (
					<div className={styles.panelSection}>
						{items.map((item, index) => (
							<ElementAbsolute
								// <ElementItem
								key={item.id}
								item={item}
								index={index}
							/>
						))}
					</div>
				) : etype.layout === "grid" ? (
					<div className={styles.panelSection}>
						{items.map((item, index) => (
							<ElementAbsolute
								key={item.id}
								item={item}
								index={index}
							/>
						))}
					</div>
				) : (
					<div className={styles.panelSection}>
						{items.map((item, index) => (
							// <ElementResponsive

							<ElementResponsive
								key={item.id}
								item={item}
								index={index}
							/>
						))}
					</div>
				)}
			</Accordion.Content>
		</Accordion>
	);
}

function useComponents(layout, search_query) {
	const additional_panels = useRecoilValue(additionPanelsState);
	const additional_elements = useRecoilValue(additionalElementsState);

	const parseData = (panel, elements) => {
		const out = [];
		panel.forEach((panel_item) => {
			const items = [];
			panel_item.items.forEach((item) => {
				const value = elements[item];
				if (value) {
					items.push(value);
				}
			});
			const fuse = new Fuse(items, {
				keys: ["name"],
				isCaseSensitive: false,
				threshold: 0.2,
			});
			const filtered_items = search_query
				? fuse.search(search_query).map((res) => res.item)
				: items;
			if (filtered_items.length > 0) {
				out.push({
					panel: panel_item,
					items: filtered_items,
				});
			}
		});
		return out;
	};

	if (layout === "responsive") {
		const out = parseData(PANEL_RES, ELEMENTS_RES);
		const add_out = parseData(additional_panels, additional_elements);
		return [...out, ...add_out];
	} else if (layout === "absolute") {
		return parseData(PANEL_ABS, ELEMENTS_ABS);
	} else if (layout === "grid") {
		return parseData(PANEL_ABS, ELEMENTS_ABS);
	}
}
function ComponentPanel() {
	const isMobile = useMedia("(max-width: 800px)");
	const etype = useRecoilValue(editorTypeState);
	const [search_query, setSearchQuery] = useState("");
	const panels = useComponents(etype.layout, search_query);

	return (
		<div
			style={{
				// height: "100vh",
				height: "100%",
				minHeight: "70vh",
				display: "flex",
				flexDirection: "column",
				paddingBottom: "100px",
				overflowY: "auto",
				position: "relative",
			}}
		>
			<div
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					zIndex: 2,
				}}
			>
				<Input
					placeholder="Search elements"
					value={search_query}
					onChange={(e) => setSearchQuery(e.target.value)}
					fluid
				/>
			</div>
			<div
				style={{
					// flex: 1,
					// maxHeight: "70vh",
					position: "absolute",
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					overflow: "auto",
					paddingTop: "40px",
				}}
			>
				{panels?.map((panelItem) => {
					return (
						<ConponentSection
							key={panelItem.panel.id}
							panelItem={panelItem.panel}
							items={panelItem.items}
							etype={etype}
							isMobile={isMobile}
						/>
					);
				})}
				<DataComponents />
			</div>
		</div>
	);
}

export default ComponentPanel;
