/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import React, { createRef, useState } from "react";
import QRCode from "react-qr-code";
import { decodeStyle, parseStyle } from "../../../../junctionAbs";
import ElementWrapper from "../wrappers/ElementWrapper";

const useRefDimensions = (ref) => {
    const [dimensions, setDimensions] = useState({ width: 1, height: 2 });
    React.useEffect(() => {
        if (ref.current) {
            const { current } = ref;
            const boundingRect = current.getBoundingClientRect();
            const { width, height } = boundingRect;
            if (Math.round(width) !== dimensions.width) {
                setDimensions({
                    width: Math.round(width),
                    height: Math.round(height),
                });
            }
        }
    }, [ref]);
    return dimensions;
};

function QRCodeView({ id, db, gs }) {
    const divRef = createRef();
    const dimensions = useRefDimensions(divRef);

    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);
    log.d(ps, "PS");

    let input = ps.input;

    return (
        <ElementWrapper id={id}>
            <div
                ref={divRef}
                style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ...decodeStyle(ps.style, gs),
                    backgroundColor: input.backgroundColor,
                }}
            >
                <QRCode
                    value={input.content}
                    size={dimensions.width - (input.gap || 20)}
                    fgColor={input.color}
                    bgColor={input.backgroundColor}
                />
            </div>
        </ElementWrapper>
    );
}

export default QRCodeView;
