import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import styles from "./styles/PanelSection.module.css";
import { useRecoilValue } from "recoil";
import { dataPageState } from "../db/dataDb";
import { currentBookPageState } from "../db/bookConfig";
import log from "cslog";
import { startCase } from "lodash";
import { getAssetURL, getFileURL } from "../_helper/image";
import { height } from "../customize/ComplexOptions";

function DataComponents() {
	const currentBookPage = useRecoilValue(currentBookPageState);
	const page_db = useRecoilValue(dataPageState(currentBookPage.pid)) || {};

	log.d(page_db, "page_db in DC");

	return (
		<div>
			<div className={styles.main_heading}>Data Components</div>
			{Object.keys(page_db).map((key) => {
				const item = page_db[key];
				if (!item.isValid) {
					return null;
				}
				const fields = item?.data?.fields || [];
				if (item.block_type === "fillable") {
					return (
						<DataComponentPanel
							item={item}
							key={key}
							id={key}
							fields={fields}
						/>
					);
				} else if (item.block_type === "CSV") {
					return (
						<DataComponentPanel
							item={item}
							key={key}
							id={key}
							fields={fields}
						/>
					);
				} else if (item.block_type === "qureal_database") {
					if (item?.data?.display) {
						return <DataComponentPanel
							item={item}
							key={key}
							id={key}
							fields={Object.keys(item?.data?.display).map(key => ({
								name: key,
								type: item?.data?.display[key].type
							}))}
						/>
					} else {
						return <div>
							<p style={{ padding: "10px" }}>Error with {item.name}</p>
							<p>{JSON.stringify(item)}</p>
						</div>
					}
				}
				return null;
			})}
		</div>
	);
}

export default DataComponents;

function DataComponentPanel({ id, item, fields }) {
	const [active, setActive] = useState(true);

	return (
		<Accordion fluid styled key={"data"} style={{ background: "#F3F3F3" }}>
			<Accordion.Title
				active={active}
				style={{ background: "#F3F3F3" }}
				onClick={() => setActive(!active)}
			>
				<Icon name="dropdown" />
				{item.name}
			</Accordion.Title>
			<Accordion.Content active={active}>
				<div className={styles.panelSection}>
					{fields.map((f, index) => {
						if (f.type === "image") {
							return <ImageItem
								label={f.name}
								content={{
									id: id,
									field: f.name,
								}}
							/>
						} else {
							return (
								<HeaderItem
									label={f.name}
									content={{
										id: id,
										field: f.name,
									}}
								/>
							)
						}
					})}
				</div>
			</Accordion.Content>
		</Accordion>
	);
}

function ImageItem({ label, content }) {
	log.d(content, "ImageItem content");
	return (
		<div
			draggable={true}
			onDragStart={(e) => {
				e.dataTransfer.setData(
					"text/plain",
					JSON.stringify({
						type: "img",
						elementGroup: "basic",
						data: {
							input: {
								imageSource: {
									...content,
									temp_info: {
										width: 200,
										height: 200,
									}
								},
								imageFit: "contain"
							},
						},
					})
				);
			}}
			className={styles.panelItem}
		>
			<div>{startCase(label)}</div>
		</div>
	);
}


function HeaderItem({ label, content }) {
	return (
		<div
			draggable={true}
			onDragStart={(e) => {
				e.dataTransfer.setData(
					"text/plain",
					JSON.stringify({
						type: "header",
						elementGroup: "basic",
						data: {
							input: {
								content: content,
							},
						},
					})
				);
			}}
			className={styles.panelItem}
		>
			<div>{startCase(label)}</div>
		</div>
	);
}
