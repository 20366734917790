import { useMutation } from "react-query";
import { secureAxios } from "../../_helper/auth";
import { queryClient } from "../../_helper/query";

export const useUploadScreenshot = (onDone) => {
    return useMutation(
        (newItem) => {
            return secureAxios.post("/files", newItem).then((res) => res.data);
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries("templates");
                onDone(data);
            },
        }
    );
};

export const useUploadFile = () => {
    return useMutation(
        (newItem) => {
            return secureAxios.post("/files", newItem).then((res) => res.data);
        },
        {
            onSuccess: () => queryClient.invalidateQueries("files"),
        }
    );
};

export const useUploadPhoto = (onDone = () => {}) => {
    return useMutation(
        (newItem) => {
            return secureAxios.post("/files", newItem).then((res) => res.data);
        },
        {
            onSuccess: () => queryClient.invalidateQueries("photos"),
            onSettled: (data) => {
                onDone(data);
            },
        }
    );
};

export const useArchiveFile = () => {
    return useMutation(
        (id) => {
            return secureAxios.patch(`/files/${id}`, {
                archived: true,
            });
        },
        {
            onSuccess: () => queryClient.invalidateQueries("photos"),
        }
    );
};

export const useRestoreFile = () => {
    return useMutation(
        (id) => {
            return secureAxios.patch(`/files/${id}`, {
                archived: false,
            });
        },
        {
            onSuccess: () => queryClient.invalidateQueries("photos"),
        }
    );
};

export const useDeleteFile = () => {
    return useMutation(
        (id) => {
            return secureAxios.delete(`/files/${id}`);
        },
        {
            onSuccess: () => queryClient.invalidateQueries("photos"),
        }
    );
};

export const useCreateFolder = () => {
    return useMutation(
        (newItem) => {
            return secureAxios
                .post("/folders", newItem)
                .then((res) => res.data);
        },
        {
            onSuccess: () => queryClient.invalidateQueries("folders"),
        }
    );
};
