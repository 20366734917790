const PANEL_ABSOLUTE = [
    {
        id: "primary",
        name: "Basic Elements",
        items: [
            "section",
            "header",
            // "hbox",
            "p",
            "htmltext",
            "button",
            "buttonGroup",
            "link",
            "img",
            "video",
            "icon",
            "divider",
            "blank",
            // "shape",
            "slidingText",
            "list",
            "richDialog",
        ],
    },
    {
        id: "widgets",
        name: "Widget Elements",
        items: [
            "tabs",
            "table",
            "rating",
            "socialIcons",
            // "tags",
            "codeview",
            "gallery",
            "imageCarousel",
            "qrCode",
            "loopBuilder",
            "filters",
            "viewer3d",
            "audioPlayer",
            "iconList",
            // "countdown",
            "map",
            // "pageNumber",
            "navMenu",
            "verticalMenu",
            "typingText",
            "googleCalendar",
            "iframe",
            "formContact",
            "slider",
            "pdfViewer",
            "form",
            "popupForm",
            "search",
            "pagination",
            "breadcrumb",
        ],
        // items: [],
    },
    {
        id: "charts",
        name: "Charts",
        items: [
            // "pie",
            "barChart",
            "lineChart",
        ],
    },
];

export default PANEL_ABSOLUTE;
