import React, { useMemo, useRef } from "react";
import styles from "./styles/AutoFit.module.css";
import log from "cslog";
import { useDivSize } from "./utils";
// import FillablePanel from "../../dynamicdata/fillable/FillablePanel";
import { FillablePanel } from "../junctionAbs";
import ExportPanel from "./ExportPanel";

function AutoFit({ layout, gap = 5, children, page_content, is_preview, protected_access = {}, creation_id }) {
	// const { width: dw, height: dh } = useWindowDimensions();
	const divRef = useRef(null);
	const { width: dw, height: dh } = useDivSize(divRef);

	const { width, height } = layout?.pageSize?.value;

	log.d([dw, dh], "Device WH");
	log.d([width, height], "Creation WH");

	const dims = useMemo(() => {
		const rw = dw / width;
		const rh = dh / height;

		const zoom = rw < rh ? rw : rh;

		return {
			zoom: zoom,
		};
	}, [width, height, dw, dh]);

	return (
		<div className={styles.body_wrapper}>
			<div
				ref={divRef}
				className={styles.wrapper}
				style={
					{
						// background: "white"
						// background: "black",
						// width: "60%",
					}
				}
			>
				<div
					className={styles.box}
					style={{
						transform: `scale(${dims.zoom - gap / 100})`,
						backgroundColor: "hotpink",
					}}
				>
					{children}
				</div>
			</div>
			{/* <div
                style={{
                    width: "200px",
                    height: "100vH",
                    backgroundColor: "cyan",
                }}
            >
                <h2>Hello Fillable</h2>
            </div> */}
			{!is_preview && (
				<FillablePanel
					content={page_content}
					wrapper_styles={
						{
							// position: "absolute",
							// right: 0,
							// bottom: 0,
							// padding: "15px",
						}
					}
					dims={{
						width: Math.ceil(width * dims.zoom),
						height: Math.ceil(height * dims.zoom),
					}}
					protected_access={protected_access}
					creation_id={creation_id}
				/>
			)}

			{!is_preview && (
				<ExportPanel
					width={Math.ceil(width * dims.zoom)}
					height={Math.ceil(height * dims.zoom)}
				/>
			)}
		</div>
	);
}

export default AutoFit;
