/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import produce from "immer";
import div, { Fragment, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import WebFont from "webfontloader";

import styles from "./styles/COptions.module.scss";

import { Range } from "react-range";

import { SHAPES } from "../data/extra/shapeData";

import log from "cslog";
import {
    Button,
    Dropdown,
    Form,
    Icon,
    Label,
    Popup
} from "semantic-ui-react";

import {
    booleanOption,
    ColorConfigPicker,
    colorOption,
    ControlledTextArea,
    fileOption,
    iconOption,
    longTextOption,
    optionRowIcon,
    QColorPicker,
    rangeOption,
    selectOption,
    textOption,
} from "./SimpleOptions";

import GradientPicker from "../widgets/GradientPicker";
import ImagePanel from "../widgets/ImagePanel";
// import { decodeAttribute } from "../parser/styleparser";
import classNames from "classnames";
import { startCase } from "lodash";
import { useRecoilValue } from "recoil";
import { SIZE_ICONS } from "../data/extra/customIcons";
import { pageSizes } from "../data/extra/pageSizeData";
import { themeFontSetState } from "../db/gstyleDb";
import { rolesState } from "../db/permissions";
import { pageSectionsSelector, pageState } from "../db/siteConfig";
import DynamicDataOption from "../dynamic/DynamicDataOption";
import DynamicDataView from "../dynamic/DynamicDataView";
import { ItemDropdown } from "../dynamic/fields/db";
import { decodeAttribute } from "../parser/parserAbsolute";
import { isString } from "../utils";
import FontPicker, { QFontLoader } from "../widgets/FontPicker";
import SlateEditorDialog from "../widgets/slateeditor/SlateEditorDialog";
import { imgArrayOptions } from "./DataOptions";

function saveSelection() {
    if (window.getSelection) {
        const sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            return sel.getRangeAt(0);
        }
    } else if (document.selection && document.selection.createRange) {
        return document.selection.createRange();
    }
    return null;
}

function restoreSelection(range) {
    if (range) {
        if (window.getSelection) {
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (document.selection && range.select) {
            range.select();
        }
    }
}

const HyperlinkButton = () => {
    const [sr, setSr] = useState(null);
    const [linkText, setLinkText] = useState("");
    const [open, setOpen] = useState(false);

    const justLink = () => {
        restoreSelection(sr);
        let link = linkText;
        if (!link.startsWith("http")) {
            link = `https://${linkText}`;
        }
        let selectedText = document.getSelection();
        document.execCommand(
            "insertHTML",
            false,
            '<a href="' + link + '" target="_blank">' + selectedText + "</a>"
        );
        // var selection = document.getSelection();
        // document.execCommand("createLink", false, link);
        // selection.anchorNode.parentElement.target = "_blank";
        setOpen(false);
    };

    return (
        <Popup
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            on="click"
            trigger={
                <Button className={styles.item} key="hyperlink" icon>
                    <Icon name="linkify" />
                </Button>
            }
        >
            <Form>
                <input
                    placeholder="Paste link here"
                    value={linkText}
                    onChange={(e) => setLinkText(e.target.value)}
                    onFocus={(e) => {
                        const range = saveSelection();
                        setSr(range);
                        log.d(range, "Range");
                    }}
                />
                <Button
                    primary
                    style={{ marginTop: "10px" }}
                    onClick={justLink}
                >
                    Add Link
                </Button>
            </Form>
        </Popup>
    );
};

export const textEditOptions = (
    basic = false,
    allow = [
        ["bold", "bold", <b>Bold</b>],
        ["italic", "italic", <i>Italic</i>],
        ["strikeThrough", "strikethrough", <strike>Strike</strike>],
        ["underline", "underline", <u>Underline</u>],
        [
            "subscript",
            "subscript",
            <span>
                Sub <sub>script</sub>
            </span>,
        ],
        ["removeFormat", "remove", <span>Remove Formatting</span>],
    ]
) => {
    return (
        <Form.Field key="selectedText">
            <label>Selected Text</label>
            <div className={styles.grid4}>
                {allow.map(([item, icon, html]) => (
                    <Popup
                        key={item}
                        inverted
                        content={html}
                        trigger={
                            <Button
                                className={styles.item}
                                key={item}
                                icon
                                onMouseDown={(evt) => {
                                    evt.preventDefault(); // Avoids loosing focus from the editable area
                                    // let window = document.getElementById(
                                    // 	"resDev"
                                    // ).contentWindow;
                                    let selection = window.getSelection();

                                    if (
                                        selection.extentOffset -
                                        selection.baseOffset ===
                                        0
                                    ) {
                                        log.p(
                                            "Nothing is selected. so selecting all"
                                        );
                                        // document.execCommand(
                                        // 	"selectAll",
                                        // 	false,
                                        // 	null
                                        // );
                                        window.document.execCommand(
                                            "selectAll",
                                            false,
                                            null
                                        );
                                    }
                                    // let done = document.execCommand(
                                    // 	item,
                                    // 	false,
                                    // 	null
                                    // ); // Send the command to the browser
                                    let done = window.document.execCommand(
                                        item,
                                        false,
                                        null
                                    );
                                    log.d(done, "Done");
                                }}
                            >
                                <Icon name={icon} />
                            </Button>
                        }
                    />
                ))}
                {!basic && <HyperlinkButton />}
                {!basic && (
                    <Popup
                        key="unlink"
                        inverted
                        content="Remove link"
                        trigger={
                            <Button
                                className={styles.item}
                                key="hyperlink"
                                icon
                                onClick={() => {
                                    document.execCommand(
                                        "unlink",
                                        false,
                                        false
                                    );
                                }}
                            >
                                <Icon name="unlink" />
                            </Button>
                        }
                    />
                )}
            </div>
        </Form.Field>
    );
};

export const widthsOption = (key, value, onChange) => {
    return <h3>Widths</h3>;
};

export const colWidths = (key, value, onChange) => {
    return (
        <Form.Field>
            <label>Column width</label>
            <Range
                step={1}
                min={0}
                max={100}
                values={[value[0]]}
                onChange={(values) =>
                    onChange(key, [values[0], 100 - values[0]])
                }
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "6px",
                            width: "100%",
                            backgroundColor: "#5f9de8",
                            borderRadius: "2px",
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "15px",
                            width: "15px",
                            backgroundColor: "white",
                            border: "1px solid black",
                            borderRadius: "2px",
                        }}
                    />
                )}
            />
        </Form.Field>
    );
};

export const numCol = (key, value, onChange) => {
    return (
        <Form.Field>
            <label>Number of columns</label>
            <div className={styles.grid3}>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange(key, 1);
                    }}
                    primary={value === 1}
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(-45deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange(key, 2);
                    }}
                    primary={value === 2}
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(-45deg)",
                        }}
                    />
                </Button>
            </div>
        </Form.Field>
    );
};

export const setDims = (key, value, setAttribute) => {
    log.d(value, "Dim Value");
    let { width, height } = value;
    let mwidth = parseInt(width.substring(0, width.indexOf("%")));
    return (
        <Form.Field>
            <label>Width</label>
            <Range
                step={1}
                min={0}
                max={100}
                values={[mwidth]}
                onChange={(values) =>
                    setAttribute(key, {
                        width: values[0] + "%",
                        height: height,
                    })
                }
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "6px",
                            width: "100%",
                            backgroundColor: "#5f9de8",
                            borderRadius: "2px",
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "15px",
                            width: "15px",
                            backgroundColor: "white",
                            border: "1px solid black",
                            borderRadius: "2px",
                        }}
                    />
                )}
            />
        </Form.Field>
    );
};

export const textAlign = (key, value, onChange) => {
    return (
        <Form.Field key={key}>
            <label>{key}</label>
            <div className={styles.grid3}>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange("left");
                    }}
                    primary={value === "left"}
                >
                    <Icon name="align left" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange("center");
                    }}
                    primary={value === "center"}
                >
                    <Icon name="align center" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange("right");
                    }}
                    primary={value === "right"}
                >
                    <Icon name="align right" />
                </Button>
            </div>
        </Form.Field>
    );
};

export const verticleAlign = (key, value, onChange) => {
    return (
        <Form.Field>
            <label>{key}</label>
            <div className={styles.grid3}>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange("flex-start");
                    }}
                    primary={value === "flex-start"}
                >
                    <Icon name="arrow up" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange("center");
                    }}
                    primary={value === "center"}
                >
                    <Icon name="circle outline" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange("flex-end");
                    }}
                    primary={value === "flex-end"}
                >
                    <Icon name="arrow down" />
                </Button>
            </div>
        </Form.Field>
    );
};

export const alignSelf = (key, value, onChange) => {
    return (
        <Form.Field>
            <label>{key}</label>
            <div className={styles.grid3}>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange(key, "flex-start");
                    }}
                    primary={value === "flex-start"}
                >
                    <Icon name="align left" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange(key, "center");
                    }}
                    primary={value === "center"}
                >
                    <Icon name="align center" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange(key, "flex-end");
                    }}
                    primary={value === "flex-end"}
                >
                    <Icon name="align right" />
                </Button>
            </div>
        </Form.Field>
    );
};

export const setPosition = (key, value, setValue) => {
    const { alignItems, justifyContent, flexDirection } = value;
    let h = "alignItems";
    let v = "justifyContent";
    if (flexDirection === "row") {
        h = "justifyContent";
        v = "alignItems";
    }

    return (
        <Form.Field>
            <label>Position</label>
            <div className={styles.grid3}>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "flex-start",
                            [v]: "flex-start",
                        });
                    }}
                    primary={
                        justifyContent === "flex-start" &&
                        alignItems === "flex-start"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(-45deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "center",
                            [v]: "flex-start",
                        });
                    }}
                    primary={
                        justifyContent === "center" &&
                        alignItems === "flex-start"
                    }
                >
                    <Icon name="arrow up" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "flex-end",
                            [v]: "flex-start",
                        });
                    }}
                    primary={
                        justifyContent === "flex-end" &&
                        alignItems === "flex-start"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(45deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "flex-start",
                            [v]: "center",
                        });
                    }}
                    primary={
                        justifyContent === "flex-start" &&
                        alignItems === "center"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(-90deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "center",
                            [v]: "center",
                        });
                    }}
                    primary={
                        justifyContent === "center" && alignItems === "center"
                    }
                >
                    <Icon name="circle outline" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "flex-end",
                            [v]: "center",
                        });
                    }}
                    primary={
                        justifyContent === "flex-end" && alignItems === "center"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(90deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "flex-start",
                            [v]: "flex-end",
                        });
                    }}
                    primary={
                        justifyContent === "flex-start" &&
                        alignItems === "flex-end"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(-135deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "center",
                            [v]: "flex-end",
                        });
                    }}
                    primary={
                        justifyContent === "center" && alignItems === "flex-end"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(180deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        console.log("Setting Attribute");
                        setValue("position", {
                            ...value,
                            [h]: "flex-end",
                            [v]: "flex-end",
                        });
                    }}
                    primary={
                        justifyContent === "flex-end" &&
                        alignItems === "flex-end"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(135deg)",
                        }}
                    />
                </Button>
            </div>
        </Form.Field>
    );
};

export const alignVertical = (key, setProperty) => {
    return (
        <div className="hbox" key={key}>
            <button onClick={() => setProperty(key, "flex-start")}>T</button>
            <button onClick={() => setProperty(key, "center")}>M</button>
            <button onClick={() => setProperty(key, "flex-end")}>B</button>
        </div>
    );
};

export const justifyContent = (key, setProperty) => {
    return (
        <div className="hbox" key={key}>
            <button onClick={() => setProperty(key, "flex-start")}>L</button>
            <button onClick={() => setProperty(key, "center")}>C</button>
            <button onClick={() => setProperty(key, "flex-end")}>R</button>
        </div>
    );
};

export const fontSize = (key, value, setProperty) => {
    return (
        <Form.Field>
            <label>Font Size</label>
            <Range
                step={1}
                min={0}
                max={100}
                values={[value]}
                onChange={(values) => setProperty(key, values[0])}
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "6px",
                            width: "100%",
                            backgroundColor: "#5f9de8",
                            borderRadius: "2px",
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "15px",
                            width: "15px",
                            backgroundColor: "white",
                            border: "1px solid black",
                            borderRadius: "2px",
                        }}
                    />
                )}
            />
            {/* <div className="hbox" key={key}>
				<button onClick={() => setProperty(key, value + 1)}>+</button>
				<button onClick={() => setProperty(key, value - 1)}>-</button>
			</div> */}
        </Form.Field>
    );
};

export const colorP = (key, value, setProperty) => {
    return (
        <Form.Field>
            <label>{key}</label>
            <Popup
                trigger={
                    <Label
                        as="a"
                        style={{
                            color: "black",
                            backgroundColor: value,
                            border: "1px solid black",
                        }}
                        key={value}
                    >
                        {value}
                    </Label>
                    // <Button
                    // 	style={{
                    // 		backgroundColor: value,
                    // 		color: "white",
                    // 	}}
                    // 	bordered
                    // >
                    // 	Line Color
                    // </Button>
                }
                eventsEnabled={true}
                on="click"
            >
                <SketchPicker
                    color={value}
                    onChangeComplete={(color) => {
                        // setCol(color.hex);
                        setProperty(key, color.hex);
                    }}
                />
            </Popup>
        </Form.Field>
    );
};

export const gcolorOption = (label, value, onChange, gs) => {
    let val = value;
    const isObj = typeof value === "object";

    let decodedVal = value;
    if (isObj) {
        log.d(gs, "GS in gcoloroption");
        decodedVal = decodeAttribute(value, gs);
    }

    return (
        <div.Fragment key={label}>
            <Form.Field key={label}>
                {/* <label>{key}</label> */}
                {label && <label>{label}</label>}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2px",
                    }}
                >
                    <Popup
                        trigger={
                            <Button
                                icon="tint"
                                // primary={!isObj}
                                style={{
                                    // color: "#7f7f7f",
                                    border: "1px solid #f1f1f1",
                                    ...(isObj ? {} : { color: decodedVal }),
                                }}
                            />
                        }
                        eventsEnabled={true}
                        on="click"
                        style={{
                            zIndex: 110000,
                        }}
                    >
                        {/* <SketchPicker
                            color={val}
                            onChangeComplete={(color) => {
                                // onChange(color.hex);
                                onChange(
                                    `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                                );
                            }}
                        /> */}
                        <QColorPicker
                            color={decodedVal}
                            // setColor={onChange}
                            setColor={(color) =>
                                onChange(
                                    `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                                )
                            }
                        />
                    </Popup>

                    <ColorConfigPicker
                        active={isObj}
                        value={decodedVal}
                        setValue={(newVal) => onChange(newVal)}
                    />

                    {/* <GradientPicker
                        label={null}
                        value={val?.type === "gradient" ? val.params : null}
                        onSelect={(newBack) => {
                            console.log("Selected: " + newBack);
                            onChange({
                                type: "gradient",
                                params: newBack,
                                value: newBack.fvalue,
                            });
                        }}
                        isSelected={val?.type === "gradient"}
                    /> */}
                </div>
            </Form.Field>
        </div.Fragment>
    );
};

export const setBackground = (key, value, setValue, gs, pstyle) => {
    // log.d(value, "Background Value");
    let vtype = value.type;
    let params = value.params;
    if (typeof params === "object") {
        if ("type" in params) {
            params = decodeAttribute(params, gs);
        }
    } else if (vtype === "config") {
        params = decodeAttribute(value, gs);
    }

    return (
        <div.Fragment key={key}>
            <Form.Field key={key}>
                <label>{key}</label>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        flexWrap: "wrap",
                        gap: "2px",
                    }}
                >
                    <Popup
                        inverted
                        content="Transparent"
                        trigger={
                            <Button
                                icon="ban"
                                primary={vtype === "transparent"}
                                onClick={() => {
                                    setValue("background", {
                                        type: "transparent",
                                        params: "transparent",
                                        value: "transparent",
                                    });
                                }}
                            />
                        }
                    />
                    <ColorConfigPicker
                        active={vtype === "config"}
                        value={params}
                        setValue={(newVal) => setValue("background", newVal)}
                    />
                    <Popup
                        trigger={
                            <Button
                                icon="tint"
                                primary={vtype === "simple"}
                                style={{
                                    color: "#7f7f7f",
                                    border: "1px solid #f1f1f1",
                                    ...(vtype === "simple"
                                        ? { backgroundColor: value.value }
                                        : {}),
                                }}
                            />
                        }
                        eventsEnabled={true}
                        on="click"
                        pinned={false}
                    >
                        <QColorPicker
                            color={params}
                            setColor={(color) =>
                                setValue("background", {
                                    type: "simple",
                                    params: color.rgb,
                                    value: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
                                })
                            }
                        />
                    </Popup>

                    <GradientPicker
                        label={null}
                        value={vtype === "gradient" ? params : null}
                        onSelect={(newBack) => {
                            console.log("Selected: " + newBack);
                            setValue("background", {
                                type: "gradient",
                                params: newBack,
                                value: newBack.fvalue,
                            });
                        }}
                        isSelected={vtype === "gradient"}
                    />

                    <Popup
                        inverted
                        content="Image Background"
                        trigger={
                            <ImagePanel
                                button={
                                    <Button
                                        icon="image outline"
                                        primary={vtype === "image"}
                                    />
                                }
                                onPhoto={(photo) => {
                                    setValue("background", {
                                        type: "image",
                                        params: photo,
                                        // value: `url(${photo.full}) no-repeat`,
                                        value: `url(${photo.full})`,
                                    });
                                }}
                            />
                        }
                    />
                    <Popup
                        inverted
                        content="Slideshow"
                        trigger={
                            <Button
                                icon="images outline"
                                primary={vtype === "slideshow"}
                                onClick={() => {
                                    setValue("background", {
                                        type: "slideshow",
                                        params: "transparent",
                                        value: "transparent",
                                    });
                                }}
                            />
                        }
                    />
                </div>
            </Form.Field>

            {vtype === "slideshow" && (
                <Fragment>
                    {imgArrayOptions(
                        "Slideshow Images",
                        params.images || [],
                        (newVal) => {
                            // const one_img =
                            //     newVal.length > 0
                            //         ? newVal[0]["full"]
                            //         : "transparent";
                            setValue("background", {
                                ...value,
                                params: {
                                    ...params,
                                    images: newVal,
                                },
                                // value: one_img,
                            });
                        }
                    )}
                    {selectOption(
                        "Slideshow Effect",
                        params.effect || "fade",
                        (newVal) =>
                            setValue("background", {
                                ...value,
                                params: {
                                    ...params,
                                    effect: newVal,
                                },
                            }),
                        [
                            ["Fade", "fade"],
                            ["Zoom Out", "zoom_out"],
                            ["Slide", "slide"],
                        ]
                    )}
                    {rangeOption(
                        "Duration",
                        params.duration || 3000,
                        (newVal) =>
                            setValue("background", {
                                ...value,
                                params: {
                                    ...params,
                                    duration: newVal,
                                },
                            }),
                        500,
                        10000,
                        100
                    )}
                    {rangeOption(
                        "Transition Duration",
                        params.transitionDuration || 800,
                        (newVal) =>
                            setValue("background", {
                                ...value,
                                params: {
                                    ...params,
                                    transitionDuration: newVal,
                                },
                            }),
                        100,
                        5000,
                        100
                    )}
                </Fragment>
            )}

            {/* {vtype === "image" && */}
            {(vtype === "image" || vtype === "slideshow") &&
                booleanOption(
                    "Parallex",
                    pstyle.backgroundAttachment === "fixed",
                    (newVal) => {
                        if (newVal) {
                            setValue("backgroundAttachment", "fixed");
                        } else {
                            setValue("backgroundAttachment", "local");
                        }
                    }
                )}
            {/* {(vtype === "image" || vtype === "slideshow") && */}
            {booleanOption(
                "Full Screeen Background",
                pstyle?.fullScreenBackground,
                (newVal) => setValue("fullScreenBackground", newVal)
            )}
            {(vtype === "image" || vtype === "slideshow") &&
                selectOption(
                    "Background Panning",
                    pstyle.backgroundPanning,
                    (newVal) => setValue("backgroundPanning", newVal),
                    [
                        ["None", "pan_no"],
                        ["Slower", "pan_slower"],
                        ["Slow", "pan_slow"],
                        ["Normal", "pan_normal"],
                        ["Fast", "pan_fast"],
                        ["Faster", "pan_faster"],
                        ["Very Slow", "pan_very_slow"],
                    ]
                )}
            {vtype === "image" &&
                pstyle.backgroundPanning === "pan_no" &&
                selectOption(
                    "Background Position",
                    pstyle.backgroundPosition,
                    (newVal) => setValue("backgroundPosition", newVal),
                    [
                        ["Top", "top center"],
                        ["Center", "center center"],
                        ["Bottom", "bottom center"],
                    ]
                )}
        </div.Fragment>
    );
};

export const fillOption = (key, value, setValue, gs, pstyle) => {
    // log.d(value, "Background Value");
    let vtype = value.type;
    let params = value.params;
    if (typeof params === "object") {
        if ("type" in params) {
            params = decodeAttribute(params, gs);
        }
    } else if (vtype === "config") {
        params = decodeAttribute(value, gs);
    }

    return (
        <div.Fragment key={key}>
            <Form.Field key={key}>
                <label>{startCase(key)}</label>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        flexWrap: "wrap",
                        gap: "2px",
                    }}
                >
                    <ColorConfigPicker
                        active={vtype === "config"}
                        value={params}
                        setValue={(newVal) => setValue(key, newVal)}
                    />
                    <Popup
                        trigger={
                            <Button
                                icon="tint"
                                primary={vtype === "simple"}
                                style={{
                                    color: "#7f7f7f",
                                    border: "1px solid #f1f1f1",
                                    ...(vtype === "simple"
                                        ? { backgroundColor: value.value }
                                        : {}),
                                }}
                            />
                        }
                        eventsEnabled={true}
                        on="click"
                    >
                        <QColorPicker
                            color={params}
                            setColor={(color) =>
                                setValue(key, {
                                    type: "simple",
                                    params: color.rgb,
                                    value: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
                                })
                            }
                        />
                    </Popup>

                    <GradientPicker
                        label={null}
                        value={vtype === "gradient" ? params : null}
                        onSelect={(newBack) => {
                            console.log("Selected: " + newBack);
                            setValue(key, {
                                type: "gradient",
                                params: newBack,
                                value: newBack.fvalue,
                            });
                        }}
                        isSelected={vtype === "gradient"}
                    />
                </div>
            </Form.Field>
        </div.Fragment>
    );
};



// TODO: Add Font Library (picker)
// Use this API to get list of fonts
// https://developers.google.com/fonts/docs/developer_api


function FontFamilyConfigPicker({ onValue }) {
    const fontSets = useRecoilValue(themeFontSetState);

    return (
        <Dropdown icon={null} trigger={<Button icon="circle" />}>
            <Dropdown.Menu direction="left">
                <Dropdown.Header icon="globe" content="FontSet" />
                <Dropdown.Menu scrolling>
                    {Object.keys(fontSets).map((item) => (
                        <Dropdown.Item
                            key={item}
                            text={
                                <span
                                    style={{
                                        fontFamily: fontSets[item],
                                    }}
                                >
                                    {startCase(item)}
                                </span>
                            }
                            value={item}
                            onClick={() =>
                                onValue({
                                    type: "config",
                                    value: item,
                                })
                            }
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export const fontFamily = (key, value, onValue) => {
    const fontList = [
        [`Default`, `inherit`],
        [`Arial`, `Arial, Helvetica, sans-serif`],
        [`Times New Roman`, `"Times New Roman", Times, serif`],
        [`Georgia`, `Georgia, serif`],
        [`Halvetica`, `Helvetica, sans-serif`],
        // [`Comic Sans MS`, `"Comic Sans MS, Comic Sans, cursive`],
        [`Verdana`, `Verdana, Geneva, sans-serif`],
        [`Lucida Console`, `"Lucida Console", Monaco, monospace`],
    ];

    // GOOGLE_FONTS.map((font) => {
    //     fontList.push([font, font]);
    // });

    const options = fontList.map((item) => {
        return {
            key: item[0],
            text: <span style={{ fontFamily: item[1] }}>{item[0]}</span>,
            value: item[1],
        };
    });

    const handleChange = (e, { value }) => onValue(value);

    // return <FontFamilyConfigPicker key={key} value={value} onValue={onValue} />;

    return (
        <div.Fragment key={key}>
            <Form.Field key={key}>
                <label>{key}</label>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "7px",
                    }}
                >
                    <Dropdown
                        placeholder="Select Font"
                        options={options}
                        selection
                        value={value}
                        onChange={handleChange}
                    />
                    {/* <Button icon="circle" /> */}
                    <FontFamilyConfigPicker value={value} onValue={onValue} />
                </div>
            </Form.Field>
            <QFontLoader />
        </div.Fragment>
    );
};

// Depend on height, position will appear or hide.
export const height = (key, value, onValue) => {
    const optionList = [
        ["Auto", "auto"],
        ["Screen Height", "fullscreen"],
        ["Fixed", "fixed"],
    ];

    const lookup = {
        auto: { type: "auto", params: "auto", value: "auto" },
        fullscreen: { type: "fullscreen", params: 100, value: "100vh" },
        fixed: { type: "fixed", params: 200, value: "200px" },
    };

    const options = optionList.map((item) => {
        return {
            key: item[0],
            text: item[0],
            value: item[1],
        };
    });

    const handleChange = (e, { value }) => {
        log.d(value, "Changing Height to");
        onValue(lookup[value]);
    };

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "3fr 2fr",
                gap: "5px",
            }}
        >
            <div>
                <Form.Field>
                    <label>{key}</label>
                    <Dropdown
                        placeholder="Select Option"
                        options={options}
                        selection
                        value={value.type}
                        onChange={handleChange}
                        fluid
                    />
                </Form.Field>
            </div>
            {value.type === "fullscreen" && (
                <div>
                    <Form.Field>
                        <label>Percentage</label>
                        <Form.Input
                            placeholder="100"
                            fluid
                            value={value.params}
                            onChange={(eve) => {
                                onValue({
                                    type: value.type,
                                    params: eve.target.value,
                                    value: eve.target.value + "vh",
                                });
                            }}
                        />
                    </Form.Field>
                </div>
            )}
            {value.type === "fixed" && (
                <div>
                    <Form.Field>
                        <label>Pixels</label>
                        <Form.Input
                            placeholder="100"
                            fluid
                            value={value.params}
                            onChange={(eve) => {
                                onValue({
                                    type: value.type,
                                    params: eve.target.value,
                                    value: eve.target.value + "px",
                                });
                            }}
                        />
                    </Form.Field>
                </div>
            )}
        </div>
    );
};

// combiner= a function to convert from params to value
export const fourSide = (label, value, setValue, combiner) => {
    const params = value.params;
    const sliderValue = value.type === "single" ? params : params[params.cside];

    const onChange = (newVal) => {
        if (value.type === "single") {
            setValue({
                type: "single",
                params: newVal,
                value: `${newVal}px`,
            });
        } else {
            setValue({
                type: "multiple",
                params: {
                    ...params,
                    [params.cside]: newVal,
                },
                value: combiner(params),
            });
        }
    };

    const onSideChange = (side) => {
        if (value.type === "single") {
            setValue({
                ...value,
                type: "multiple",
                params: {
                    cside: side,
                    top: value.params,
                    right: value.params,
                    bottom: value.params,
                    left: value.params,
                },
            });
        } else {
            setValue({
                ...value,
                type: "multiple",
                params: { ...params, cside: side },
            });
        }
    };
    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <div className={styles.grid5}>
                {[
                    ["top", "arrow up"],
                    ["right", "arrow right"],
                    ["bottom", "arrow down"],
                    ["left", "arrow left"],
                ].map((item, index) => {
                    return (
                        <Button
                            key={index}
                            className={styles.item}
                            icon
                            onClick={() => {
                                onSideChange(item[0]);
                            }}
                            primary={params.cside === item[0]}
                        >
                            <Icon name={item[1]} />
                        </Button>
                    );
                })}

                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        const temp =
                            value.type === "single"
                                ? params
                                : parseInt(
                                    (params.left +
                                        params.right +
                                        params.top +
                                        params.bottom) /
                                    4
                                );
                        setValue({
                            type: "single",
                            params: temp,
                            value: temp,
                        });
                    }}
                    primary={value.type === "single"}
                >
                    <Icon
                        name={value.type === "single" ? "lock" : "lock open"}
                    />
                </Button>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                <Form.Field style={{ flex: 1 }}>
                    <label style={{ marginBottom: "25px" }}></label>
                    <Range
                        step={1}
                        min={0}
                        max={100}
                        // max={max}
                        values={[sliderValue]}
                        onChange={(values) => {
                            log.p("Range changed to " + value);
                            onChange(values[0]);
                        }}
                        renderTrack={({ props, children }) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: "6px",
                                    width: "100%",
                                    backgroundColor: "#5f9de8",
                                    borderRadius: "2px",
                                }}
                            >
                                {children}
                            </div>
                        )}
                        renderThumb={({ props }) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: "15px",
                                    width: "15px",
                                    backgroundColor: "white",
                                    border: "1px solid black",
                                    borderRadius: "2px",
                                }}
                            />
                        )}
                    />
                </Form.Field>

                <Form.Input
                    style={{
                        width: "70px",
                        marginTop: "8px",
                        paddingLeft: "1px",
                    }}
                    type="number"
                    value={sliderValue}
                    onChange={(eve) => onChange(parseInt(eve.target.value))}
                />
            </div>
        </Form.Field>
    );
};

// Seperate panel with name ANIMATION
export const transform = (label, value, setValue) => {
    const params = value.params;
    log.d(value, "Tarnsform Value");

    const combiner = (params) => {
        let value = "";
        Object.keys(params).map((item) => {
            switch (item) {
                case "rotate":
                    value += ` rotate(${params[item]}deg)`;
                case "translateX":
                case "translateY":
                    value += ` translate(${params["translateX"]}px, ${params["translateY"]}px)`;
            }
        });
        return value;
    };

    return (
        <Fragment>
            <p>Open seperate panel here with name ANIMATE</p>
            {rangeOption(
                "Rotate",
                params.rotate,
                (newVal) => {
                    setValue({
                        ...value,
                        params: {
                            ...value.params,
                            rotate: newVal,
                        },
                        value: combiner({
                            ...value.params,
                            rotate: newVal,
                        }),
                    });
                },
                0,
                180
            )}
            {rangeOption(
                "Move Horizontal",
                params.translateX,
                (newVal) => {
                    setValue({
                        ...value,
                        params: {
                            ...value.params,
                            translateX: newVal,
                        },
                        value: combiner({
                            ...value.params,
                            translateX: newVal,
                        }),
                    });
                },
                -50,
                50
            )}
            {rangeOption(
                "Move Vertical",
                params.translateY,
                (newVal) => {
                    setValue({
                        ...value,
                        params: {
                            ...value.params,
                            translateY: newVal,
                        },
                        value: combiner({
                            ...value.params,
                            translateY: newVal,
                        }),
                    });
                },
                -50,
                50
            )}
        </Fragment>
    );
};

export const imgSizeOption = (label, value, setValue) => {
    const optionList = [
        ["Custom", "custom"],
        ["Full", "full"],
        ["Mini", "mini"],
        ["Small", "small"],
        ["Medium", "medium"],
        ["Big", "big"],
        ["Large", "large"],
    ];

    const LOOKUP = {
        mini: {
            icon: SIZE_ICONS["xs"],
            iconType: "custom",
            params: 50,
            value: "50px",
        },
        small: {
            icon: SIZE_ICONS["s"],
            iconType: "custom",
            params: 100,
            value: "100px",
        },
        medium: {
            icon: SIZE_ICONS["m"],
            iconType: "custom",
            params: 250,
            value: "250px",
        },
        big: {
            icon: SIZE_ICONS["l"],
            iconType: "custom",
            params: 500,
            value: "500px",
        },
        large: {
            icon: SIZE_ICONS["xl"],
            iconType: "custom",
            params: 1000,
            value: "1000px",
        },
        full: {
            icon: "fas fa-expand",
            iconType: "fa",
            params: 0,
            value: "100%",
        },
        custom: {
            icon: "fas fa-ellipsis-h",
            iconType: "fa",
            params: value.params > 0 ? value.params : 250,
            value: `${value.params > 0 ? value.params : 250}px`,
        },
    };

    return (
        <div.Fragment key={label}>
            <Form.Field key={label}>
                <label>{label}</label>
                {/* <Dropdown
                    placeholder={label}
                    options={options}
                    selection
                    value={value.type}
                    onChange={handleChange}
                /> */}
                <CustomRowIcons
                    options={LOOKUP}
                    value={value}
                    setValue={(key, item) => {
                        setValue({
                            type: key,
                            params: item.params,
                            value: item.value,
                        });
                    }}
                />
            </Form.Field>
            {value.type === "custom" &&
                rangeOption(
                    "Custom Image Size",
                    value.params,
                    (newVal) =>
                        setValue({
                            type: "custom",
                            params: newVal,
                            value: `${newVal}px`,
                        }),
                    10,
                    1000
                )}
        </div.Fragment>
    );
};

export const iconSizeOption = (label, value, setValue) => {
    const LOOKUP = {
        mini: {
            icon: SIZE_ICONS["xs"],
            iconType: "custom",
            params: 15,
            value: "15px",
        },
        small: {
            icon: SIZE_ICONS["s"],
            iconType: "custom",
            params: 24,
            value: "24px",
        },
        medium: {
            icon: SIZE_ICONS["m"],
            iconType: "custom",
            params: 40,
            value: "40px",
        },
        big: {
            icon: SIZE_ICONS["l"],
            iconType: "custom",
            params: 70,
            value: "70px",
        },
        large: {
            icon: SIZE_ICONS["xl"],
            iconType: "custom",
            params: 120,
            value: "120px",
        },
        custom: {
            icon: "fas fa-ellipsis-h",
            iconType: "fa",
            params: value.params > 0 ? value.params : 40,
            value: `${value.params > 0 ? value.params : 40}px`,
        },
    };

    return (
        <div.Fragment key={label}>
            <Form.Field key={label}>
                <label>{label}</label>
                <CustomRowIcons
                    options={LOOKUP}
                    value={value}
                    setValue={(key, item) => {
                        setValue({
                            type: key,
                            params: item.params,
                            value: item.value,
                        });
                    }}
                />
            </Form.Field>
            {value.type === "custom" &&
                rangeOption(
                    "Custom Image Size",
                    value.params,
                    (newVal) =>
                        setValue({
                            type: "custom",
                            params: newVal,
                            value: `${newVal}px`,
                        }),
                    1,
                    500
                )}
        </div.Fragment>
    );
};

// export const htmlOption = (label, value, setValue) => {
//     log.d(value, "Value in HTML Option");
//     return (
//         <Form.Field>
//             <QurealTextEditorDialog
//                 html={value}
//                 setHtml={setValue}
//                 trigger={
//                     <Button primary icon labelPosition="left">
//                         <Icon name="edit" /> Edit
//                     </Button>
//                 }
//             />
//         </Form.Field>
//     );
// };

export const htmlOption = (label, value, setValue, trigger = null) => {
    log.d(value, "Value in HTML Option");
    return (
        <Form.Field>
            <SlateEditorDialog
                html={value}
                setHtml={setValue}
                trigger={
                    trigger || (
                        <Button primary icon labelPosition="left">
                            <Icon name="edit" /> Edit Content
                        </Button>
                    )
                }
            />
        </Form.Field>
    );
};

function ParamsField({ value, setValue, auto_configure = true }) {
    log.d(value, "Value in ParamField");
    if (value.params?.paths?.length > 0) {
        return (
            <Fragment>
                {value?.params?.paths.map((path) => {
                    return longTextOption(
                        path,
                        value?.params?.dyno?.[path] || "",
                        (newVal) =>
                            setValue({
                                ...value,
                                params: {
                                    ...value.params,
                                    dyno: {
                                        ...(value.params?.dyno || {}),
                                        [path]: newVal,
                                    },
                                },
                                value: {
                                    ...value.value,
                                    dyno: {
                                        ...(value.value?.dyno || {}),
                                        [path]: newVal,
                                    },
                                },
                            }),
                        auto_configure
                    );
                })}
            </Fragment>
        );
    }
    return <></>;
}

function SectionDropdown({ value, setValue }) {
    // const page = useRecoilValue(elementState(value.params.id));
    const sections = useRecoilValue(pageSectionsSelector(value.params.id));

    log.d(value, "Value in SD");
    const getPageOptions = () => {
        return sections.map((item) => {
            return {
                key: item.id,
                text: item.name,
                value: item,
            };
        });
    };

    return (
        <Dropdown
            placeholder="Select Section"
            options={getPageOptions()}
            selection
            value={value.params.section}
            onChange={(e, { value: newParam }) => {
                setValue({
                    ...value,
                    params: {
                        ...value.params,
                        section: newParam,
                    },
                    value: {
                        ...value.value,
                        section: newParam,
                    },
                });
            }}
            fluid
        />
    );
}

function RoleDropdown({ value, setValue }) {
    const roles = useRecoilValue(rolesState);

    const getRoleOptions = () => {
        return roles.map((item) => {
            return {
                key: item,
                text: item,
                value: item,
            };
        });
    };

    return (
        <Dropdown
            placeholder="Select Role"
            options={getRoleOptions()}
            selection
            value={value}
            onChange={(e, { value: newParam }) => {
                setValue(newParam);
            }}
            fluid
        />
    );
}

function PageDropdown({ value, setValue }) {
    const pages = useRecoilValue(pageState);

    // log.d(value, "Value in PageDropdown");

    const getPageOptions = () => {
        return pages.map((item) => {
            return {
                key: item.id,
                text: item.name,
                value: item,
            };
        });
    };

    return (
        <Dropdown
            placeholder="Select Page"
            options={getPageOptions()}
            selection
            value={{
                id: value?.params?.id,
                name: value?.params?.name,
            }}
            onChange={(e, { value: newParam }) => {
                setValue({
                    ...value,
                    params: newParam,
                    value: newParam,
                });
            }}
            fluid
        />
    );
}

export const pageOption = (label, value, setValue) => {
    return (
        <Form.Field>
            <label>{label}</label>
            <PageDropdown
                // value={value || { params: { id: "home", name: "Home" } }}
                value={value}
                setValue={setValue}
            />
        </Form.Field>
    );
};

export const roleOption = (label, value, setValue) => {
    return (
        <Form.Field>
            <label>{label}</label>
            <RoleDropdown value={value} setValue={setValue} />
        </Form.Field>
    );
};

export const formOption = (label, value, setValue) => {
    return (
        <>
            <ItemDropdown
                label="Choose From"
                table={{
                    id: "qdb_form",
                    display_template: "{{name}}",
                }}
                value={value}
                setValue={setValue}
            />
        </>
    );
};

export const recordIdPicker = (label, value, setValue) => {
    return (
        <>
            {booleanOption("Edit Record", value?.enable, (new_val) => {
                setValue({
                    enable: new_val,
                    type: "db",
                });
            })}
            {value?.enable && (
                <Form.Field>
                    {longTextOption(
                        "Record ID",
                        // value?.params?.dyno?.[path] || "",
                        value?.params || "",
                        (new_val) =>
                            setValue({
                                ...value,
                                type: "db",
                                params: new_val,
                            }),
                        false,
                        false
                    )}
                </Form.Field>
            )}
        </>
    );
};

export const actionOption = (label, value, setValue) => {
    // const OPTIONS = ["none", "page", "link", "file", "scrollTo"];
    // const OPTIONS = ["link", "page", "download"];
    const OPTIONS = [
        "none",
        "page",
        "link",
        "download",
        "call",
        "whatsapp",
        "mail",
        "map",
    ];

    const options = OPTIONS.map((item) => {
        return {
            key: item,
            text: startCase(item),
            value: item,
        };
    });

    const mapping = {
        none: {},
        link: { link: "https://qureal.com", newTab: true },
        download: {},
        page: { id: "home" },
        call: "",
        whatsapp: {
            recipientNumber: "",
            message: "",
        },
        mail: {
            recipientId: "",
        },
        map: {
            location: "",
            // lat: 0,
            // lng: 0,
        },
    };

    const handleChange = (e, { value: newType }) => {
        setValue({
            type: newType,
            params: mapping[newType],
        });
    };

    log.d(value, "Value Action");

    return (
        <div.Fragment key={label}>
            <Form.Field>
                <label>{label}</label>
                <Dropdown
                    placeholder="Select Option"
                    options={options}
                    selection
                    value={value.type}
                    onChange={handleChange}
                    fluid
                />
            </Form.Field>
            {/* {value.type === "page" && (
                <Form.Field>
                    <label>Page Number</label>
                    <Input
                        value={value.params}
                        type="number"
                        onChange={(e) =>
                            setValue({
                                ...value,
                                params: e.target.value,
                                value: e.target.value,
                            })
                        }
                    />
                </Form.Field>
            )} */}
            {value.type === "page" && (
                <Fragment>
                    <Form.Field>
                        <label>Select Page</label>
                        <PageDropdown value={value} setValue={setValue} />
                    </Form.Field>
                    <ParamsField value={value} setValue={setValue} />
                    <Form.Field>
                        <label>Scroll To</label>
                        <SectionDropdown value={value} setValue={setValue} />
                    </Form.Field>
                </Fragment>
            )}
            {value.type === "link" && (
                <Form.Field>
                    {/* <label>New Web Address</label> */}
                    <label>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                cursor: "pointer",
                            }}
                        >
                            <span>New Web Address</span>
                            <DynamicDataOption
                                value={value?.params?.link}
                                onChange={(new_val) => {
                                    log.d(new_val, "Dynamic link new value");
                                    setValue({
                                        ...value,
                                        params: {
                                            ...value.params,
                                            link: new_val,
                                        },
                                        value: "temp",
                                    });
                                }}
                                ftype="string"
                                auto_configure={false}
                            />
                        </div>
                    </label>
                    {isString(value?.params?.link) && (
                        <ControlledTextArea
                            value={value?.params?.link}
                            onChange={(eve) =>
                                setValue({
                                    ...value,
                                    params: {
                                        ...value.params,
                                        link: eve.target.value,
                                    },
                                    value: eve.target.value.startsWith("http")
                                        ? eve.target.value
                                        : "http://" + eve.target.value,
                                })
                            }
                        />
                    )}
                    {!isString(value?.params?.link) && (
                        <DynamicDataView
                            value={value?.params?.link}
                            reset={() => {
                                setValue({
                                    ...value,
                                    params: {
                                        ...value.params,
                                        link: "",
                                    },
                                    value: "",
                                });
                            }}
                        />
                    )}
                </Form.Field>
            )}
            {value.type === "link" &&
                textOption("Web Address", value.params.link, (newVal) =>
                    setValue({
                        ...value,
                        params: {
                            ...value.params,
                            link: newVal,
                        },
                        value: newVal.startsWith("http")
                            ? newVal
                            : "http://" + newVal,
                    })
                )}

            {["link"].includes(value.type) &&
                booleanOption(
                    "Open in New Tab",
                    value.params.newTab,
                    (newNewTab) =>
                        setValue({
                            ...value,
                            params: {
                                ...value.params,
                                newTab: newNewTab,
                                popup: newNewTab ? false : value.params.popup,
                            },
                        })
                )}
            {["link"].includes(value.type) &&
                booleanOption(
                    "Open in Popup",
                    value.params.popup,
                    (newNewTab) =>
                        setValue({
                            ...value,
                            params: {
                                ...value.params,
                                popup: newNewTab,
                                newTab: newNewTab ? false : value.params.newTab,
                            },
                        })
                )}
            {value.type === "download" &&
                fileOption("any", "File to Download", value.params, (newVal) =>
                    setValue({
                        type: "download",
                        params: newVal,
                        value: "download ka code or something",
                    })
                )}
            {value.type === "call" &&
                textOption(
                    "Phone Number",
                    value.params,
                    (newVal) =>
                        setValue({
                            type: "call",
                            params: newVal,
                            value: `tel:${newVal}`,
                        }),
                    "tel"
                )}
            {value.type === "whatsapp" &&
                textOption(
                    "Recipient Phone Number",
                    value.params?.recipientNumber,
                    (newVal) => {
                        let cleanedNumber = newVal.replace(/\D/g, "");
                        setValue({
                            type: "whatsapp",
                            params: {
                                ...value.params,
                                recipientNumber: newVal,
                            },
                            value: `https://wa.me/${cleanedNumber}?text=${value.params.message}`,
                        });
                    },
                    "tel"
                )}
            {value.type === "whatsapp" &&
                longTextOption(
                    "Message",
                    value.params?.message,
                    (newVal) => {
                        const encodedMessage = encodeURIComponent(newVal);
                        setValue({
                            type: "whatsapp",
                            params: {
                                ...value.params,
                                message: newVal,
                            },
                            value: `https://wa.me/${value.params?.recipientNumber}?text=${encodedMessage}`,
                        });
                    },
                    false
                )}
            {value.type === "mail" &&
                textOption(
                    "Recipient Mail ID",
                    value.params?.recipientId,
                    (newVal) =>
                        setValue({
                            type: "mail",
                            params: {
                                recipientId: newVal,
                            },
                            value: `mailto: ${newVal}`,
                        }),
                    "email"
                )}
            {value.type === "map" &&
                textOption(
                    "Google Map Location",
                    value.params.location,
                    (newVal) =>
                        setValue({
                            type: "map",
                            params: {
                                ...value.params,
                                location: newVal,
                            },
                            value: `https://maps.google.com/?q=${newVal}`,
                        })
                )}
        </div.Fragment>
    );
};

export const shapeClipOption = (label, value, setValue, gs) => {
    const options = Object.keys(SHAPES).map((item) => {
        return {
            key: item,
            text: startCase(item),
            value: item,
        };
    });

    const handleChange = (e, { value: newType }) => {
        setValue({
            ...value,
            type: newType,
            path: SHAPES[newType],
        });
    };

    return (
        <Fragment>
            <Form.Field>
                <label>{label}</label>
                <Dropdown
                    placeholder="Select Option"
                    options={options}
                    selection
                    value={value.type}
                    onChange={handleChange}
                    fluid
                />
            </Form.Field>

            {value.type !== "none" && (
                <Fragment>
                    {/* {colorOption( */}
                    {gcolorOption(
                        "Shape Color",
                        value.color,
                        (newCol) =>
                            setValue({
                                ...value,
                                color: newCol,
                            }),
                        gs
                    )}
                    {optionRowIcon(
                        "Shape Position",
                        value.position,
                        (newPos) =>
                            setValue({
                                ...value,
                                position: newPos,
                            }),
                        [
                            ["top", "long arrow alternate up"],
                            ["bottom", "long arrow alternate down"],
                        ]
                    )}
                    {rangeOption(
                        "Shape Height",
                        value.height,
                        (newHeight) =>
                            setValue({
                                ...value,
                                height: newHeight,
                            }),
                        5,
                        200,
                        5
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export const frameShapeOption = (label, value, setValue) => {
    return (
        <div.Fragment key={label}>
            {optionRowIcon(
                label,
                value.type,
                (newVal) =>
                    setValue({
                        type: newVal,
                        params:
                            newVal === "custom"
                                ? {
                                    type: "single",
                                    params: 10,
                                    value: 10,
                                }
                                : newVal,
                        value: newVal === "custom" ? 10 : newVal,
                    }),
                [
                    ["circle", "circle"],
                    ["square", "square"],
                    ["custom", "pencil square"],
                ]
            )}
            {value.type === "custom" &&
                fourSide(
                    "Border Radius",
                    value.params,
                    (newVal) =>
                        setValue({
                            type: "custom",
                            params: newVal,
                            value: newVal.value,
                        }),
                    ({ top, right, bottom, left }) => {
                        return `${top}px ${right}px ${bottom}px ${left}px`;
                    }
                )}
        </div.Fragment>
    );
};

export const borderOption = (label, value, setMultiValue, gs, pstyle) => {
    const combiner = (type, params) => {
        return `${params.width}px ${type} ${params.color}`;
        // return `${type} ${params.color}`;
    };

    const side = "bottom";

    const setBorder = (newVal) => {
        setMultiValue({
            border: newVal,
            // borderWidth: "0px 0px 10px 0px",
        });
        // setMultiValue("borderWidth", "0px 0px 5px 0px");
        // setMultiValue("border", newVal);
    };
    return (
        <div.Fragment key={label}>
            {optionRowIcon(
                label,
                value.type,
                (newVal) =>
                    setBorder({
                        type: newVal,
                        params: {
                            width: 2,
                            color: "black",
                        },
                        value:
                            newVal === "none"
                                ? "none"
                                : combiner(newVal, {
                                    width: 2,
                                    color: "black",
                                }),
                    }),
                [
                    ["none", "dont"],
                    ["solid", "square outline"],
                    ["dashed", "expand"],
                ]
            )}
            {value.type !== "none" &&
                rangeOption(
                    "Border Width",
                    value.params.width,
                    (newWidth) => {
                        setBorder({
                            ...value,
                            params: { ...value.params, width: newWidth },
                            value: combiner(value.type, {
                                ...value.params,
                                width: newWidth,
                            }),
                        });
                    },
                    1,
                    20,
                    1
                )}
            {value.type !== "none" &&
                colorOption("Border Color", value.params.color, (newColor) => {
                    setBorder({
                        ...value,
                        params: { ...value.params, color: newColor },
                        value: combiner(value.type, {
                            ...value.params,
                            color: newColor,
                        }),
                    });
                })}
        </div.Fragment>
    );
};

export const borderOptionNew = (label, value, setMultiValue, gs, pstyle) => {
    return (
        <div.Fragment key={label}>
            {selectOption(
                label,
                pstyle.borderStyle,
                (newVal) =>
                    setMultiValue({
                        borderStyle: newVal,
                    }),
                [
                    ["None", "none"],
                    ["Solid", "solid"],
                    ["Dashed", "dashed"],
                    ["Dotted", "dotted"],
                    ["Double", "double"],
                    ["Groove", "groove"],
                    ["Ridge", "ridge"],
                ]
            )}

            {pstyle.borderStyle !== "none" &&
                fourSide(
                    "Border Width",
                    pstyle.borderWidth,
                    (newVal) => {
                        setMultiValue({
                            borderWidth: newVal,
                        });
                    },
                    ({ top, right, bottom, left }) => {
                        return `${top}px ${right}px ${bottom}px ${left}px`;
                    }
                )}

            {pstyle.borderStyle !== "none" &&
                colorOption("Border Color", pstyle.borderColor, (newColor) => {
                    setMultiValue({
                        borderColor: newColor,
                    });
                })}
        </div.Fragment>
    );
};

export const filterOption = (label, value, setValue) => {
    const optionList = [
        ["None", "none"],
        ["Blur", "blur"],
        ["Grayscale", "grayscale"],
        ["Sepia", "sepia"],
        ["Saturation", "saturate"],
        ["Brightness", "brightness"],
        ["Darkness", "darkness"],
        ["Contrast", "contrast"],
        ["Hue Rotation", "huerotate"],
        ["Invert", "invert"],
    ];

    const LOOKUP = {
        blur: { params: 3, value: "blur(3px)" },
        grayscale: { params: 100, value: "grayscale(1)" },
        sepia: { params: 100, value: "sepia(1)" },
        saturate: { params: 5, value: "saturate(5)" },
        brightness: { params: 60, value: "brightness(4)" },
        darkness: { params: 40, value: "brightness(0.6)" },
        contrast: { params: 40, value: "contrast(3)" },
        huerotate: { params: 90, value: "hue-rotate(90deg)" },
        invert: { params: 100, value: "invert(100)" },
    };

    const options = optionList.map((item) => {
        return {
            key: item[0],
            text: item[0],
            value: item[1],
        };
    });

    const handleChange = (e, { value }) =>
        setValue({
            type: value,
            ...LOOKUP[value],
        });

    const range_options = [
        {
            type: "blur",
            name: "Blur Intensity",
            valueFunc: (newVal) => `blur(${newVal}px)`,
            min: 0,
            max: 20,
        },
        {
            type: "grayscale",
            name: "Grayscale Intensity",
            valueFunc: (newVal) => `grayscale(${newVal / 100})`,
            min: 0,
            max: 100,
        },
        {
            type: "sepia",
            name: "Seipa Intensity",
            valueFunc: (newVal) => `sepia(${newVal / 100})`,
            min: 0,
            max: 100,
        },
        {
            type: "saturate",
            name: "Saturation Amount",
            valueFunc: (newVal) => `saturate(${newVal})`,
            min: 0,
            max: 100,
        },
        {
            type: "brightness",
            name: "Brightness",
            valueFunc: (newVal) => `brightness(${1 + newVal / 30})`,
            min: 0,
            max: 100,
        },
        {
            type: "darkness",
            name: "Darkness",
            valueFunc: (newVal) => `brightness(${1 - newVal / 100})`,
            min: 0,
            max: 100,
        },
        {
            type: "contrast",
            name: "Contrast Amount",
            valueFunc: (newVal) => `contrast(${1 + newVal / 20})`,
            min: 0,
            max: 100,
        },
        {
            type: "huerotate",
            name: "Rotation Angle",
            valueFunc: (newVal) => `hue-rotate(${newVal}deg)`,
            min: 0,
            max: 360,
        },
    ];

    return (
        <div.Fragment key={label}>
            <Form.Field key={label}>
                <label>{label}</label>
                <Dropdown
                    placeholder={label}
                    options={options}
                    selection
                    value={value.type}
                    onChange={handleChange}
                />
            </Form.Field>
            {range_options.map(
                (item) =>
                    value.type === item.type &&
                    rangeOption(
                        item.name,
                        value.params,
                        (newVal) =>
                            setValue({
                                type: item.type,
                                params: newVal,
                                value: item.valueFunc(newVal),
                            }),
                        item.min,
                        item.max
                    )
            )}

            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(6, 37px)",
                    rowGap: "5px",
                    columnGap: "5px",
                }}
            ></div>
        </div.Fragment>
    );
};

export const boxShadowOption = (label, value, setValue, spread = true) => {
    const initParams = {
        hlength: 10,
        vlength: 10,
        blur_radius: 11,
        spread_radius: -7,
        color: "black",
        type: "outside",
    };
    const combiner = (type, params) => {
        return `${params.type === "outside" ? "" : params.type} ${params.hlength
            }px ${params.vlength}px ${params.blur_radius}px ${spread ? `${params.spread_radius}px` : ""
            } ${params.color}`;
    };

    return (
        <div
            key={label}
            style={{
                backgroundColor: "#f5f5f5",
                margin: "0px -18px",
                padding: "10px 18px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
            }}
        >
            {optionRowIcon(
                label,
                value.type,
                (newVal) =>
                    setValue({
                        type: newVal,
                        params: initParams,
                        value:
                            newVal === "none"
                                ? "none"
                                : combiner(newVal, initParams),
                    }),
                [
                    ["none", "dont"],
                    ["solid", "square outline"],
                ]
            )}

            {value.type !== "none" &&
                rangeOption(
                    "Horizontal Length",
                    value.params.hlength,
                    (newHLength) => {
                        setValue({
                            ...value,
                            params: { ...value.params, hlength: newHLength },
                            value: combiner(value.type, {
                                ...value.params,
                                hlength: newHLength,
                            }),
                        });
                    },
                    -50,
                    50,
                    1
                )}

            {value.type !== "none" &&
                rangeOption(
                    "Vertical Length",
                    value.params.vlength,
                    (newVLength) => {
                        setValue({
                            ...value,
                            params: { ...value.params, vlength: newVLength },
                            value: combiner(value.type, {
                                ...value.params,
                                vlength: newVLength,
                            }),
                        });
                    },
                    -50,
                    50,
                    1
                )}

            {value.type !== "none" &&
                rangeOption(
                    "Blur Radius",
                    value.params.blur_radius,
                    (newBlurRadius) => {
                        setValue({
                            ...value,
                            params: {
                                ...value.params,
                                blur_radius: newBlurRadius,
                            },
                            value: combiner(value.type, {
                                ...value.params,
                                blur_radius: newBlurRadius,
                            }),
                        });
                    },
                    0,
                    50,
                    1
                )}

            {spread &&
                value.type !== "none" &&
                rangeOption(
                    "Spread Radius",
                    value.params.spread_radius,
                    (newSpreadRadius) => {
                        setValue({
                            ...value,
                            params: {
                                ...value.params,
                                spread_radius: newSpreadRadius,
                            },
                            value: combiner(value.type, {
                                ...value.params,
                                spread_radius: newSpreadRadius,
                            }),
                        });
                    },
                    -50,
                    50,
                    1
                )}

            {value.type !== "none" &&
                colorOption("Shadow Color", value.params.color, (newColor) => {
                    setValue({
                        ...value,
                        params: { ...value.params, color: newColor },
                        value: combiner(value.type, {
                            ...value.params,
                            color: newColor,
                        }),
                    });
                })}

            {spread &&
                value.type !== "none" &&
                selectOption(
                    "Shadow Type",
                    value.params.type,
                    (newType) => {
                        setValue({
                            ...value,
                            params: { ...value.params, type: newType },
                            value: combiner(value.type, {
                                ...value.params,
                                type: newType,
                            }),
                        });
                    },
                    [
                        ["Outside", "outside"],
                        ["Inside", "inset"],
                    ]
                )}
        </div>
    );
};

export const classesOption = (label, value, setValue) => {
    return (
        <Fragment>
            {Object.keys(value).map((clsName) => {
                return booleanOption(
                    startCase(clsName.substring(2)),
                    value[clsName],
                    (newVal) =>
                        setValue({
                            ...value,
                            [clsName]: newVal,
                        })
                );
            })}
        </Fragment>
    );
};

export const SectionStructurePicker = ({
    options,
    value,
    setValue,
    itemPerRow = 3,
}) => {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(${itemPerRow}, 1fr)`,
                gap: "20px",
            }}
        >
            {options.map((item) => {
                return (
                    <div
                        style={{
                            width: "100px",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            gap: "3px",
                            borderRadius: "3px",
                            overflow: "hidden",
                            cursor: "pointer",
                        }}
                        css={css`
                            div {
                                background: #e1e1e1;
                            }
                            &:hover div {
                                background: royalblue;
                                opacity: 0.5;
                            }
                        `}
                        onClick={() => {
                            setValue(item);
                        }}
                    >
                        {item.map((w) => (
                            <div
                                style={{
                                    height: "100%",
                                    // backgroundColor: "#e1e1e1",
                                    width: `${w}%`,
                                }}
                            ></div>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

export const SECTION_STRUCTURES = [
    [100],
    [50, 50],
    [33.3, 33.3, 33.3],
    [25, 25, 25, 25],
    [20, 20, 20, 20, 20],
    [16.66, 16.66, 16.66, 16.66, 16.66, 16.66],
    [30, 70],
    [70, 30],
    [25, 25, 50],
    [50, 25, 25],
    [25, 50, 25],
    [15, 70, 15],
];
export const sectionStructureOption = (label, value, setValue) => {
    // const options = SECTION_STRUCTURES;
    const options = SECTION_STRUCTURES.filter(
        (item) => item.length === value.length
    );

    log.d(value, "Widths Value");
    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <Popup
                on="click"
                position="bottom left"
                pinned
                trigger={<Button>Choose</Button>}
            >
                <SectionStructurePicker
                    value={value}
                    setValue={setValue}
                    options={options}
                />
            </Popup>
        </Form.Field>
    );
};

function CustomRowIcons({ options, value, setValue }) {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 32px)",
                rowGap: "5px",
                columnGap: "5px",
                paddingTop: "4px",
                paddingBottom: "4px",
            }}
        >
            {Object.keys(options).map((key) => {
                const item = options[key];
                const icon = item["icon"];
                const isSelected = key === value.type;
                return (
                    <Button
                        key={key}
                        style={{
                            margin: 0,
                            // padding: "12px auto",
                            padding: "2px",
                            height: "36px",
                        }}
                        icon
                        primary={isSelected}
                        onClick={() => setValue(key, item)}
                    >
                        {item.iconType === "custom" ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox={icon.vb}
                                style={{
                                    // height: "12px",
                                    height: "10px",
                                }}
                            >
                                <title>Asset 97</title>
                                <path
                                    d={icon.d}
                                    style={{
                                        fill: isSelected ? "white" : "#555",
                                    }}
                                    strokeMiterlimit="10"
                                    strokeWidth="9"
                                />
                            </svg>
                        ) : (
                            <i
                                className={icon}
                                style={{
                                    fontSize: "1.2rem",
                                }}
                            ></i>
                        )}
                    </Button>
                );
            })}
        </div>
    );
}

export const headerTagOption = (label, value, setValue) => {
    const LOOKUP = {
        h5: {
            icon: SIZE_ICONS["xs"],
            iconType: "custom",
            params: 18,
            value: "18px",
        },
        h4: {
            icon: SIZE_ICONS["s"],
            iconType: "custom",
            params: 20,
            value: "20px",
        },
        h3: {
            icon: SIZE_ICONS["m"],
            iconType: "custom",
            params: 24,
            value: "24px",
        },
        h2: {
            icon: SIZE_ICONS["l"],
            iconType: "custom",
            params: 30,
            value: "30px",
        },
        h1: {
            icon: SIZE_ICONS["xl"],
            iconType: "custom",
            params: 36,
            value: "36px",
        },
        div: {
            icon: "fas fa-ellipsis-h",
            iconType: "fa",
            params: value.params > 0 ? value.params : 20,
            value: `${value.params > 0 ? value.params : 20}px`,
        },
    };

    return (
        <div.Fragment key={label}>
            <Form.Field key={label}>
                <label>{label}</label>

                <CustomRowIcons
                    options={LOOKUP}
                    value={value}
                    setValue={(key, item) => {
                        setValue({
                            type: key,
                            params: item["params"],
                            value: item["value"],
                        });
                    }}
                />
            </Form.Field>
            {value.type === "div" &&
                rangeOption(
                    "Custom Font Size",
                    value.params,
                    (newVal) =>
                        setValue({
                            type: "div",
                            params: newVal,
                            value: `${newVal}px`,
                        }),
                    5,
                    150
                )}
        </div.Fragment>
    );
};

export const buttonTypeOptions = (label, value, setValue) => {
    const optionList = [
        ["Text", "text"],
        ["Icon", "icon"],
        ["Text + Icon", "text_icon"],
    ];

    const LOOKUP = {
        text: {
            params: { text: "Click Me", uppercase: false },
            value: "Click Me",
        },
        icon: {
            params: {
                icon: {
                    params: { type: "fa", name: "home" },
                    value: "fas fa-home",
                },
            },
            value: "fas fa-home",
        },
        text_icon: {
            params: {
                text: "Click Me",
                uppercase: false,
                icon: {
                    params: { type: "fa", name: "home" },
                    value: "fas fa-home",
                },
            },
            value: "fa fa-home",
        },
    };

    const options = optionList.map((item) => {
        return {
            key: item[0],
            text: item[0],
            value: item[1],
        };
    });

    const handleChange = (e, { value }) =>
        setValue({
            type: value,
            ...LOOKUP[value],
        });

    log.d(value, "Value in Buttotype");

    return (
        <div.Fragment key={label}>
            <Form.Field key={label}>
                <label>{label}</label>
                <Dropdown
                    placeholder={label}
                    options={options}
                    selection
                    value={value.type}
                    onChange={handleChange}
                />
            </Form.Field>
            {(value.type === "icon" || value.type === "text_icon") &&
                iconOption("Button Icon", value.params.icon, (newVal) =>
                    setValue({
                        type: value.type,
                        params: {
                            ...value.params,
                            icon: newVal,
                        },
                        value: `${newVal}px`,
                    })
                )}
            {value.type === "text_icon" &&
                booleanOption(
                    "Icon at Right",
                    value.params.icon_right,
                    (newVal) =>
                        setValue({
                            type: value.type,
                            params: {
                                ...value.params,
                                icon_right: newVal,
                            },
                            value: `${newVal}px`,
                        })
                )}
            {(value.type === "text" || value.type === "text_icon") &&
                textOption("Button Text", value.params.text, (newVal) =>
                    setValue({
                        type: value.type,
                        params: {
                            ...value.params,
                            text: newVal,
                        },
                        value: `${newVal}px`,
                    })
                )}
            {(value.type === "text" || value.type === "text_icon") &&
                booleanOption("Uppercase", value.params.uppercase, (newVal) =>
                    setValue({
                        type: value.type,
                        params: {
                            ...value.params,
                            uppercase: newVal,
                        },
                        value: `${newVal}px`,
                    })
                )}
        </div.Fragment>
    );
};

export const box3dDimsOption = (bookLayout, setBookLayout) => {
    // dimArray= [[sizeName, dimName]]
    const updateValue = (newVal, dimArray) => {
        let out = { ...bookLayout };
        dimArray.forEach((dim) => {
            const sizeName = dim[0];
            const dimName = dim[1];
            out = {
                ...out,
                [sizeName]: {
                    ...out[sizeName],
                    pageSize: {
                        ...out[sizeName]["pageSize"],
                        value: {
                            ...out[sizeName]["pageSize"]["value"],
                            [dimName]: newVal,
                        },
                    },
                },
            };
        });
        setBookLayout(out);
    };
    return (
        <div>
            <h5>Box Dimentions</h5>
            {rangeOption(
                `Length (${bookLayout.physicalUnit || "mm"})`,
                bookLayout.front.pageSize.value.width,
                (newVal) =>
                    updateValue(newVal, [
                        ["front", "width"],
                        ["top", "width"],
                        ["capEnd", "width"],
                    ]),
                20,
                1000
            )}
            {rangeOption(
                `Width (${bookLayout.physicalUnit || "mm"})`,
                bookLayout.side.pageSize.value.width,
                (newVal) =>
                    updateValue(newVal, [
                        ["side", "width"],
                        ["top", "height"],
                        ["capLeft", "width"],
                        ["capRight", "width"],
                    ]),
                20,
                1000
            )}
            {rangeOption(
                `Height (${bookLayout.physicalUnit || "mm"})`,
                bookLayout.front.pageSize.value.height,
                (newVal) =>
                    updateValue(newVal, [
                        ["front", "height"],
                        ["side", "height"],
                        ["sideStick", "width"],
                    ]),
                20,
                1000
            )}
            <h5>Cap Dimentions</h5>
            {rangeOption(
                `Cap Height (${bookLayout.physicalUnit || "mm"})`,
                bookLayout.capLeft.pageSize.value.height,
                (newVal) =>
                    updateValue(newVal, [
                        ["capLeft", "height"],
                        ["capRight", "height"],
                    ]),
                20,
                1000
            )}
            <CoordinateOption
                label="Side Cap Path"
                path={bookLayout.capLeft.clip.path}
                setPath={(newPath) => {
                    setBookLayout(
                        produce(bookLayout, (draft) => {
                            draft.capLeft.clip.path = newPath;
                            draft.capRight.clip.path = newPath;
                        })
                    );
                }}
            />
            {rangeOption(
                `Cap End Height (${bookLayout.physicalUnit || "mm"})`,
                bookLayout.capEnd.pageSize.value.height,
                (newVal) =>
                    setBookLayout(
                        produce(bookLayout, (draft) => {
                            draft.capEnd.pageSize.value.height = newVal;
                        })
                    )
            )}
            {rangeOption(
                `Side Stick Height (${bookLayout.physicalUnit || "mm"})`,
                bookLayout.sideStick.pageSize.value.height,
                (newVal) =>
                    setBookLayout(
                        produce(bookLayout, (draft) => {
                            draft.sideStick.pageSize.value.height = newVal;
                        })
                    )
            )}
        </div>
    );
};

export const CoordinateOption = ({ label, path, setPath }) => {
    const updatePoint = (index, aindex, newVal) => {
        const newPoints = produce(path, (draft) => {
            draft[index][aindex] = newVal;
        });
        setPath(newPoints);
    };
    const addPoint = (index) => {
        const newPoints = [...path];
        newPoints.splice(index, 0, [11, 11]);
        setPath(newPoints);
    };

    const removePoint = (index) => {
        const newPoints = [...path];
        newPoints.splice(index, 1);
        setPath(newPoints);
    };

    return (
        <Form.Field>
            <label>{label}</label>
            <div>
                {path.map((p, index) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "rows",
                                alignItems: "center",
                                gap: "5px",
                            }}
                        >
                            <span
                                style={{
                                    padding: "5px",
                                    fontWeight: "bold",
                                    fontSize: "1.2rem",
                                    color: "#555",
                                }}
                            >
                                {index}
                            </span>
                            <input
                                type="number"
                                value={p[0]}
                                onChange={(eve) =>
                                    updatePoint(index, 0, eve.target.value)
                                }
                                min={0}
                                max={100}
                            />
                            <input
                                type="number"
                                value={p[1]}
                                onChange={(eve) =>
                                    updatePoint(index, 1, eve.target.value)
                                }
                                min={0}
                                max={100}
                            />
                            <Button
                                icon
                                style={{
                                    marginRight: 0,
                                }}
                                basic
                                color="red"
                                onClick={() => removePoint(index)}
                            >
                                <Icon name="close" />
                            </Button>
                            <Button
                                icon
                                basic
                                color="green"
                                onClick={() => addPoint(index + 1)}
                            >
                                <Icon name="add" />
                            </Button>
                        </div>
                    );
                })}
            </div>
        </Form.Field>
    );
};

export const pageSizeOption = (label, value, setValue, info) => {
    // log.d(info, "Info in pageSzieOption");
    if (["mobile", "desktop"].includes(info.currentSizeName)) {
        return <></>;
    }
    if (info.type.startsWith("packaging")) {
        return null;
    }
    const optionList = pageSizes[info.type].map((item) => [item.name, item.id]);
    const LOOKUP = {};
    pageSizes[info.type].forEach((item) => {
        if (item.id === "custom") {
            LOOKUP["custom"] = value.value;
        } else {
            LOOKUP[item.id] = item.value;
        }
    });

    const options = optionList.map((item) => {
        return {
            key: item[0],
            text: item[0],
            value: item[1],
        };
    });

    const handleChange = (e, { value }) => {
        log.d(
            {
                type: value,
                value: LOOKUP[value],
            },
            "setting value to"
        );
        setValue({
            type: value,
            value: LOOKUP[value],
        });
    };
    return (
        <div.Fragment key={label}>
            <Form.Field key={label}>
                <label>{label}</label>
                <Dropdown
                    placeholder={label}
                    options={options}
                    selection
                    value={value.type}
                    onChange={handleChange}
                />
            </Form.Field>
            {value.type === "custom" &&
                rangeOption(
                    "Width",
                    value.value.width,
                    (newVal) =>
                        setValue({
                            type: "custom",
                            value: {
                                width: newVal,
                                height: value.value.height,
                            },
                        }),
                    10,
                    1000
                )}
            {value.type === "custom" &&
                rangeOption(
                    "Height",
                    value.value.height,
                    (newVal) =>
                        setValue({
                            type: "custom",
                            value: {
                                width: value.value.width,
                                height: newVal,
                            },
                        }),
                    10,
                    1000
                )}
        </div.Fragment>
    );
};

function ColorPalletOption({ label, value, setValue, gs }) {
    const [editMode, setEditMode] = useState(null);
    return (
        <div.Fragment key={label}>
            <Form.Field key={label}>
                <label>{label}</label>
                <div className={styles.colorGrid}>
                    {Object.keys(value).map((cname) => {
                        return (
                            <Popup
                                content={startCase(cname)}
                                trigger={
                                    <div
                                        style={{
                                            backgroundColor: value[cname],
                                        }}
                                    ></div>
                                }
                            />
                        );
                    })}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                    }}
                >
                    <Button primary onClick={() => setEditMode("change")}>
                        Change
                    </Button>
                    <Button primary onClick={() => setEditMode("edit")}>
                        Edit
                    </Button>
                </div>
            </Form.Field>
            {editMode === "edit" &&
                Object.entries(value).map(([key, val]) => {
                    return colorOption(
                        startCase(key),
                        val,
                        (newVal) => {
                            log.d(
                                { ...value, [key]: newVal },
                                "New Color Palette value"
                            );
                            setValue({
                                ...value,
                                [key]: newVal,
                            });
                        },
                        gs
                    );
                    // return renderOption(key, val, setStyle, gs);
                })}
        </div.Fragment>
    );
}

export const stickyOption = (label, value, setValue, gs) => {
    return (
        <Fragment>
            {selectOption(
                label,
                value.type,
                (newType) => {
                    setValue({ type: newType, offset: 0 });
                },
                [
                    ["None", "none"],
                    ["Top", "top"],
                    ["Bottom", "bottom"],
                ]
            )}
            {value.type !== "none" &&
                rangeOption(
                    `Offset from ${value.type}`,
                    value.offset,
                    (newOffset) => setValue({ ...value, offset: newOffset }),
                    -200,
                    200,
                    1
                )}
        </Fragment>
    );
};

export const sectionWidthOption = (label, value, setValue, gs) => {
    return (
        <Fragment>
            {selectOption(
                label,
                value.type,
                (newType) => {
                    setValue({ type: newType, value: 1080 });
                },
                [
                    ["Global", "global"],
                    ["Full", "full"],
                    ["Custom", "custom"],
                ]
            )}
            {value.type === "custom" &&
                rangeOption(
                    `Custom Section Width`,
                    value.value,
                    (newWidthValue) =>
                        setValue({ ...value, value: newWidthValue }),
                    10,
                    2000,
                    1
                )}
        </Fragment>
    );
};

export const colorPaletteOption = (label, value, setValue, gs) => {
    return <ColorPalletOption {...{ label, value, setValue, gs }} />;
};

function FontSetPicker({ selectedFS, setSelectedFS }) {
    const FS_OPTIONS = [
        ["Lato", "Raleway"],
        ["Italiana", "Cinzel"],
        ["Roboto Condensed", "Roboto"],
        ["Open Sans", "Merriweather"],
        ["Noto Sans", "Cinzel Decorative"],
        ["Montserrat", "Great Vibes"],
        ["Poiret One", "EB Garamond"],
        ["Mulish", "Lobster"],
        ["Droid Sans", "Chilanka"],
        ["Montserrat", "Open Sans"],
        ["Pacifico", "Amatic SC"],
        ["Source Code Pro", "Quicksand"],
        ["Permanent Marker", "Caveat"],
    ];

    useEffect(() => {
        const fonts = [];
        FS_OPTIONS.forEach((fs) => {
            fonts.push(fs[0]);
            fonts.push(fs[1]);
        });
        WebFont.load({
            google: {
                families: fonts,
            },
        });
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
            }}
        >
            {FS_OPTIONS.map((fs) => {
                const isSelected =
                    selectedFS.primaryFont === fs[0] &&
                    selectedFS.secondryFont === fs[1];
                return (
                    <div
                        // className={styles.fontSetView}
                        className={classNames(styles.fontSetView, {
                            [styles.fontSetViewSelected]: isSelected,
                        })}
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setSelectedFS({
                                primaryFont: fs[0],
                                secondryFont: fs[1],
                            });
                        }}
                    >
                        <h3
                            style={{
                                fontFamily: fs[0],
                            }}
                        >
                            {fs[0]}
                        </h3>
                        <p
                            style={{
                                fontFamily: fs[1],
                            }}
                        >
                            {/* Lazy dog jumps once dog */}
                            {fs[1]}
                        </p>
                    </div>
                );
            })}
        </div>
    );
}



function FontSetOption({ label, value, setValue, gs }) {
    const [editMode, setEditMode] = useState(null);

    useEffect(() => {
        const fonts = Object.values(value) || [];
        log.d(fonts, "Loading Fonts");
        WebFont.load({
            google: {
                families: fonts,
            },
        });
    }, [value]);

    return (
        <div.Fragment key={label}>
            <Form.Field key={label}>
                <label>{label}</label>
                <div className={styles.fontSetView}>
                    <h3
                        style={{
                            fontFamily: value.primaryFont,
                        }}
                    >
                        {value.primaryFont}
                    </h3>
                    <p
                        style={{
                            fontFamily: value.secondryFont,
                        }}
                    >
                        {/* Lazy dog jumps once dog */}
                        {value.secondryFont}
                    </p>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                    }}
                >
                    <Button primary onClick={() => setEditMode("change")}>
                        Change
                    </Button>
                    <Button primary onClick={() => setEditMode("edit")}>
                        Edit
                    </Button>
                </div>
            </Form.Field>
            {editMode === "change" && (
                <FontSetPicker
                    selectedFS={value}
                    setSelectedFS={(newFS) => setValue(newFS)}
                />
            )}
            {editMode === "edit" && (
                <Fragment>
                    <FontPicker
                        label="Primary Font"
                        selectedFont={value.primaryFont}
                        setSelectedFont={(newFont) =>
                            setValue({
                                ...value,
                                primaryFont: newFont,
                            })
                        }
                    />
                    <FontPicker
                        label="Secondry Font"
                        selectedFont={value.secondryFont}
                        setSelectedFont={(newFont) =>
                            setValue({
                                ...value,
                                secondryFont: newFont,
                            })
                        }
                    />
                </Fragment>
            )}
        </div.Fragment>
    );
}

export const fontSetOption = (label, value, setValue, gs) => {
    return <FontSetOption {...{ label, value, setValue, gs }} />;
};
