import styles from "./styles/ExportPanel.module.css";
import React from "react";

function ExportPanel({ width = 1000, height = 1000 }) {
    const handlePrint = () => {
        const style = document.createElement("style");
        style.innerHTML = `
        @page {
            size: ${width}px ${height}px;
            margin: 0;
        }
        @media print {
            body {
                margin: 0;
                padding: 0;
            }
        }
        `;
        document.head.appendChild(style);
        window.print();
        document.head.removeChild(style);
    };

    return (
        <div className={styles.wrapper}>
            <button onClick={handlePrint}>⬇ Save PDF</button>
        </div>
    );
}

export default ExportPanel;
