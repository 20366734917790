import { useSelected, ReactEditor, useSlate } from 'slate-react'
import { Transforms } from 'slate'
import DynamicDataOption from '../../../dynamic/DynamicDataOption'
import log from 'cslog'

const QVarElement = ({ attributes, children, element, lookup }) => {
  const selected = useSelected()
  const editor = useSlate()
  const path = ReactEditor.findPath(editor, element)

  log.d(element, 'QVarElemet element')

  return (
    <span
      {...attributes}
      contentEditable={false}
      data-playwright-selected={selected}
      data-slate-void
    >
      <InlineChromiumBugfix />
      {children}
      <DynamicDataOption
        value={element.data || {}}
        onChange={new_val => {
          Transforms.setNodes(editor, { data: new_val }, { at: path })
        }}
        ftype='string'
        trigger={
          <button
            style={{
              backgroundColor: 'transparent',
              border: '1px dashed #666',
              outline: 'none',
              borderRadius: '2px',
              userSelect: 'none',
              cursor: 'pointer'
            }}
          >
            {element.data?.field || 'Edit'}
          </button>
        }
      />
      <InlineChromiumBugfix />
    </span>
  )
}

export default QVarElement

const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: 0
    }}
  >
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)
