import React, { useEffect, useState } from "react"
import { Button, Form, Modal } from "semantic-ui-react"
import { getFileURL } from "../_helper/image"
import { d } from "cslog"
import RelationField from "../commons/templates/RelationField"
import log from "cslog"
import { useCreateItem } from "../actions/mutations/items"


function GraphicsTagDialog({ uploaded_files, open, setOpen }) {
	const [files, setFiles] = useState(uploaded_files)
	const [result, setResult] = useState({})
	const mutationCreate = useCreateItem("junction_directus_files_qdb_graphics_tag_1", () => { }, (data) => { }, () => { }, () => {
		log.d("Done")
		setOpen(false)
	})

	useEffect(() => {
		setFiles(uploaded_files)
	}, [uploaded_files])

	log.d(files, "Files in GraphicsTagDialog")


	const save = () => {
		log.d(result, "Result in GraphicsTagDialog")
		const data = [];
		Object.keys(result).forEach((key) => {
			result[key].forEach((tag) => {
				data.push({
					directus_files_id: key,
					qdb_graphics_tag_id: tag?.qdb_graphics_tag_id,
				})
			})
		});
		log.d(data, "Data to save")
		mutationCreate.mutate(data)
	}


	log.d(result, "Result in GraphicsTagDialog")
	return (
		<Modal
			size="tiny"
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
		>
			<Modal.Content style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				gap: "15px",
			}}>
				{files?.length > 0 && files.map((file) => (
					<div style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "flex-start",
						gap: "15px",
						width: "100%",
					}}>
						<img src={getFileURL(file?.id)} alt="Graphics"
							style={{
								maxWidth: "100px",
								maxHeight: "100px",
								border: "1px solid #ddd",
								borderRadius: "5px",
							}}
						/>
						<Form style={{ flex: 1 }}>
							<Form.Field>
								<label>Assign Tags</label>
								<RelationField
									collection="qdb_graphics_tag"
									junction_field="qdb_graphics_tag_id"
									fieldString=""
									template="{{{id}}}"
									value={result[file?.id] || []}
									onChange={newVal => {
										log.d(newVal, "Selected graphcis tags")
										setResult({
											...result,
											[file?.id]: newVal,
										})
									}}
									many={true}
									allow_add={true}
									addition_field="id"
									fluid={true}
								/>
							</Form.Field>
						</Form>
					</div>
				))}
			</Modal.Content>
			<Modal.Actions>
				<Button positive onClick={save}>Save</Button>
			</Modal.Actions>
		</Modal>
	)
}

export default GraphicsTagDialog