import React from 'react'
import Image from './elements/Image'
import Link from './elements/Link'
// import { fontSize } from "../../customize/ComplexOptions";
import log from 'cslog'
import QVarElement from './elements/QVarElement'
import TemplatePlaceholder from './elements/TemplatePlaceholder'
import QVarElementView from './elements/QVarElementView'

const SlateElement = props => {
  const { attributes, children, element } = props
  // const style = { textAlign: element.align };
  const style = {
    textAlign: element.align
    // fontSize: element.fontSize || 16,
  }
  log.d(props, 'props in SleateElement')
  log.d(attributes, 'attributes in SlateElement')

  switch (element.type) {
    case 'image':
      return <Image {...props} />
    case 'link':
      return <Link {...props} />
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case 'q_var':
      return <QVarElement {...props} />
    default:
      return (
        <p style={style} {...attributes}>
          <InlineChromiumBugfix />
          {children}
          <InlineChromiumBugfix />
        </p>
      )
  }
}

export default SlateElement

export function SlateElementView (props) {
  const { attributes, children, element } = props
  // const style = { textAlign: element.align };
  const style = {
    textAlign: element.align
    // fontSize: element.fontSize || 16,
  }
  log.d(props, 'props in SleateElement')
  log.d(attributes, 'attributes in SlateElement')

  switch (element.type) {
    case 'q_var':
      return <QVarElementView {...props} style={style} />
    default:
      return <SlateElement {...props} />
  }
}

const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: 0
    }}
  >
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)
