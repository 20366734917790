import {
    parseStyle,
    decodeStyle,
    decodeAttribute,
} from "../parser/parserAbsolute";

import { mobile, tablet, isHidden } from "../data/bp";

import { PAGE_BEFORE_ID } from "../data/defaults";

import { getFileURL, parseImgURL } from "../_helper/image";

import {
    parseImageSrc,
    parseImageArray,
    getContent,
    getRecordId,
    getTableContent,
} from "../dynamic/parser";

import { checkConditions } from "../dynamic/utils";

import { useContent } from "../dynamic/apis/query";

import { SlateViewer } from "./juntion";

import { resolveClipPath } from "../_helper/image";
import { atom } from "jotai";

const FillablePanel = () => {};

const fillableAllState = atom({});

export {
    parseStyle,
    decodeStyle,
    decodeAttribute,
    SlateViewer,
    mobile,
    tablet,
    isHidden,
    PAGE_BEFORE_ID,
    getFileURL,
    parseImgURL,
    getContent,
    useContent,
    resolveClipPath,
    parseImageSrc,
    parseImageArray,
    FillablePanel,
    checkConditions,
    getRecordId,
    getTableContent,
    fillableAllState,
};
